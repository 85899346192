// Types
import { CardSettingsContractCurrentLinkHookType } from 'containers/card-settings/views/contract/components/current/hooks/types/card-settings-contract-current-link.hook.type';
// Utilities
import { isMainCardSelected } from 'containers/card-settings/views/contract/utils/card-settings-contract.utils';
import { cardActive } from 'utils/card.utils';

const isPartnerCardOrInactive = ({
  isAddon,
  status,
}: CardSettingsContractCurrentLinkHookType): boolean =>
  !isMainCardSelected(isAddon) || !cardActive(status);

export { isPartnerCardOrInactive };
