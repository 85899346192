// Enumerations
import { AvatarIdsEnumeration } from 'containers/personal/views/photo/enumerations/avatar-ids.enumeration';
// Types
import { CardSettingsContractCurrentInsuranceBuilderReturnHandlersType } from 'containers/card-settings/views/contract/components/current/components/insurance/handlers/types/card-settings-contract-current-insurance-builder-return.handlers.type';
import { CardSettingsContractCurrentInsuranceBuilderHandlersType } from 'containers/card-settings/views/contract/components/current/components/insurance/handlers/types/card-settings-contract-current-insurance-builder.handlers.type';

const changePPiStatusToggleChangeEvent = ({
  handleChangePPiStatusToggleChangeEventTracking,
}: CardSettingsContractCurrentInsuranceBuilderHandlersType): any => {
  handleChangePPiStatusToggleChangeEventTracking();
};

const CardSettingsContractCurrentInsuranceHandlers = (
  props: CardSettingsContractCurrentInsuranceBuilderHandlersType
): CardSettingsContractCurrentInsuranceBuilderReturnHandlersType => ({
  handleChangePPiStatusToggleChangeEvent: () => changePPiStatusToggleChangeEvent(props),
});

export default CardSettingsContractCurrentInsuranceHandlers;
