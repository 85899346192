// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import AppBannerComponent from 'components/banner/banner.component';
import CardStatusComponent from 'components/card-status/card-status.component';
import CreditComponent from 'components/credit/credit.component';
import { QuickActionsComponent } from 'components/quick-actions/quick-actions.component';
import DashboardSpentComponent from 'containers/dashboard/components/spent/dashboard-spent.component';
import DashboardLastSessionComponent from './components/last-session/dashboard-last-session.component';
import { DashboardPointsComponent } from './components/points/dashboard-points.component';
import DashboardTableComponent from './components/table/dashboard-table.component';
import DashboardTopMessageComponent from './components/top-message/dashboard-top-message.component';
// Constants
import constants from './constants/dashboard.constants';
// Contexts
import { DashboardContextConsumerHOC } from './contexts/dashboard.context';
// Hooks
import useDashboardHook from 'containers/dashboard/hooks/dashboard.hook';
// Styles
import { AppCardSectionComponentStyled } from 'styles/app.styled';
import {
  DashboardContainerStyled,
  DashboardContentContainerStyled,
  DashboardLeftColumnContainerStyled,
  DashboardLeftColumnTopContainerStyled,
  DashboardRightColumnContainerStyled,
  DashboardTableAreaContainerStyled,
  DashboardWrapperCardContainerStyled,
} from './dashboard.container.styled';

const DashboardContainer = (): React.ReactElement => {
  const { pageLoader, changeOrder } = useDashboardHook();

  return (
    <DashboardContainerStyled $fetching={pageLoader}>
      <FetchErrorComponent fetching={pageLoader}>
        <DashboardTopMessageComponent />
        <DashboardContentContainerStyled>
          <DashboardLeftColumnContainerStyled>
            <DashboardLeftColumnTopContainerStyled>
              <AppCardSectionComponentStyled>
                <DashboardWrapperCardContainerStyled>
                  <CreditComponent {...constants.DASHBOARD_CREDIT_CARD_PROPS} />
                </DashboardWrapperCardContainerStyled>
              </AppCardSectionComponentStyled>
              <DashboardPointsComponent />
              {changeOrder && <DashboardSpentComponent />}
            </DashboardLeftColumnTopContainerStyled>
            <AppCardSectionComponentStyled>
              <DashboardTableAreaContainerStyled>
                <CardStatusComponent {...constants.CARD_STATUS_PROPS} />
                <QuickActionsComponent {...constants.DASHBOARD_QUICK_ACTIONS_PROPS} />
                <DashboardTableComponent />
              </DashboardTableAreaContainerStyled>
            </AppCardSectionComponentStyled>
          </DashboardLeftColumnContainerStyled>
          <DashboardRightColumnContainerStyled>
            {!changeOrder && <DashboardSpentComponent />}
            <AppBannerComponent />
          </DashboardRightColumnContainerStyled>
        </DashboardContentContainerStyled>
        <DashboardLastSessionComponent />
      </FetchErrorComponent>
    </DashboardContainerStyled>
  );
};

export default DashboardContextConsumerHOC(DashboardContainer);
