// Enumerations
import { CardContractInfoSectionsEnumeration } from 'containers/card-settings/views/contract/components/current/enumerations/card-contract-info-sections.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  BILLING_ACCOUNT,
  BILLING_CYCLE_LABEL,
  CARD_STATEMENTS,
  CARD_STATEMENTS_LINK,
  TITLE,
} from '../translations/card-settings-contract-current-billing.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_STATEMENTS_PROPS = {
  label: CARD_STATEMENTS,
  links: [
    {
      label: CARD_STATEMENTS_LINK,
      testId: 'card-settings-contract-current-billing-statements-link',
      trackingLabel: 'modifyLimit',
      url: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CARD_STATEMENTS])
      ),
    },
  ],
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_PROPS = {
  label: BILLING_ACCOUNT,
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_LINK_PROPS = {
  trackingLabel: 'modifyBillingAccount',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.IBAN_AND_BILLING, RoutesEnumeration.CHANGE_IBAN])
  ),
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_CYCLE_LINK_PROPS = {
  trackingLabel: 'modifyBillingAccount',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.IBAN_AND_BILLING, RoutesEnumeration.CHANGE_BILLING_CYCLE])
  ),
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_CYCLE_PROPS = {
  label: BILLING_CYCLE_LABEL,
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_DIVIDER_PROPS = {
  id: CardContractInfoSectionsEnumeration.BILLING,
  title: TITLE,
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_LINK_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_CYCLE_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_CYCLE_LINK_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_STATEMENTS_PROPS,
};
