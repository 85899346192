// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getModifyLinkConfiguration } from 'containers/card-settings/views/contract/components/current/configurations/card-settings-contract-current.configuration';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_PAYMENTS_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_PAYMENTS_LINK_PROPS,
} from './constants/card-settings-contract-current-control-payments.constants';
// Hooks
import useCardSettingsContractCurrentLinkHook from 'containers/card-settings/views/contract/components/current/hooks/card-settings-contract-current-link.hook';
import useCardSettingsContractCurrentControlPaymentsHook from './hooks/card-settings-contract-current-control-payments.hook';
// Utilities
import { getCurrentInternetPaymentStatusTranslation } from './utils/card-settings-contract-current-control-payments.utils';

const CardSettingsContractCurrentControlPaymentsComponent = (): React.ReactElement => {
  const { isAllowPaymentsNonSecStore } = useCardSettingsContractCurrentControlPaymentsHook();

  return (
    <CardSettingsResumeLinkComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_PAYMENTS_PROPS}
      links={getModifyLinkConfiguration({
        ...useCardSettingsContractCurrentLinkHook(),
        configuration: CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_PAYMENTS_LINK_PROPS,
      })}
      value={
        <FormattedMessageComponent
          id={getCurrentInternetPaymentStatusTranslation(isAllowPaymentsNonSecStore)}
        />
      }
    />
  );
};

export default CardSettingsContractCurrentControlPaymentsComponent;
