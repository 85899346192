// Vendors
import React from 'react';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import { CARD_SETTINGS_CONTRACT_CURRENT_CARD_EXPIRY_PROPS } from './constants/card-settings-contract-current-card-expiry.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Hooks
import useCardSettingsContractCurrentCardExpiryHook from './hooks/card-settings-contract-current-card-expiry.hook';

const CardSettingsContractCurrentCardExpiryComponent = (): React.ReactElement => {
  const { expirationDate, formatDate } = useCardSettingsContractCurrentCardExpiryHook();

  return (
    <CardSettingsResumeLinkComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_CARD_EXPIRY_PROPS}
      value={formatDate(expirationDate, MiscellaneousDateConstants.FORMAT_MM_YYYY)}
    />
  );
};

export default CardSettingsContractCurrentCardExpiryComponent;
