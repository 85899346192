// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsContractCurrentTravelTrackingMethodsType } from './types/card-settings-contract-current-travel.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const toggleCardContractTravelPlusModalEventTracking = (
  visible: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: visible ? 'open' : 'close',
    eventLabel: 'travelPlus',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsContractCurrentTravelTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsContractCurrentTravelTrackingMethodsType => ({
  handleToggleCardContractTravelPlusModalEventTracking: (visible: boolean) =>
    track(toggleCardContractTravelPlusModalEventTracking(visible)),
});

export { CardSettingsContractCurrentTravelTrackingHandlers };
