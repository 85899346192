// Vendors
import React from 'react';
// Configurations
import { getModifyLinkConfiguration } from 'containers/card-settings/views/contract/components/current/configurations/card-settings-contract-current.configuration';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_LINK_PROPS,
} from './constants/card-settings-contract-current-control-locations.constants';
// Hooks
import useCardSettingsContractCurrentLinkHook from 'containers/card-settings/views/contract/components/current/hooks/card-settings-contract-current-link.hook';
import useCardSettingsContractCurrentControlLocationsHook from './hooks/card-settings-contract-current-control-locations.hook';
// Utilities
import { getAllEnabledContinents } from './utils/card-settings-contract-current-control-locations.utils';

const CardSettingsContractCurrentControlLocationsComponent = (): React.ReactElement => {
  const { continents } = useCardSettingsContractCurrentControlLocationsHook();

  return (
    <CardSettingsResumeLinkComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_PROPS}
      links={getModifyLinkConfiguration({
        ...useCardSettingsContractCurrentLinkHook(),
        configuration: CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_LINK_PROPS,
      })}
      value={getAllEnabledContinents(continents)}
    />
  );
};

export default CardSettingsContractCurrentControlLocationsComponent;
