// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_DESCRIPTION_01_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_DESCRIPTION_02_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_TITLE_01_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_TITLE_02_PROPS,
} from 'containers/card-settings/views/contract/components/current/components/billing/components/method/components/tooltip/constants/card-settings-contract-current-billing-method-tooltip.constants';
// Styles
import { CardSettingsContractCurrentBillingMethodTooltipComponentStyled } from 'containers/card-settings/views/contract/components/current/components/billing/components/method/components/tooltip/card-settings-contract-current-billing-method-tooltip.component.styled';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  TITLE_01,
  TITLE_02,
} from 'containers/card-settings/views/contract/components/current/components/billing/components/method/components/tooltip/translations/card-settings-contract-current-billing-method-tooltip.translations';

const CardSettingsContractCurrentBillingMethodTooltipComponent = (): React.ReactElement => (
  <CardSettingsContractCurrentBillingMethodTooltipComponentStyled>
    <div>
      <ParagraphComponent {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_TITLE_01_PROPS}>
        <FormattedMessageComponent id={TITLE_01} />
      </ParagraphComponent>
      <ParagraphComponent {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_DESCRIPTION_01_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_01} />
      </ParagraphComponent>
    </div>
    <div>
      <ParagraphComponent {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_TITLE_02_PROPS}>
        <FormattedMessageComponent id={TITLE_02} />
      </ParagraphComponent>
      <ParagraphComponent {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_DESCRIPTION_02_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_02} />
      </ParagraphComponent>
    </div>
  </CardSettingsContractCurrentBillingMethodTooltipComponentStyled>
);

export default CardSettingsContractCurrentBillingMethodTooltipComponent;
