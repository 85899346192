// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Events handlers
import CardSettingsContractCurrentInsuranceHandlers from '../handlers/card-settings-contract-current-insurance.handlers';
import CardSettingsContractCurrentInsuranceTrackingHandlers from '../handlers/card-settings-contract-current-insurance.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsContractCurrentCreditHookType } from './types/card-settings-contract-current-credit.hook.type';

const useCardSettingsContractCurrentInsuranceHook =
  (): CardSettingsContractCurrentCreditHookType => {
    const { formatMessage } = useCbIntl();

    return {
      ...CardSettingsContractCurrentInsuranceHandlers(
        AppTrackingHook(CardSettingsContractCurrentInsuranceTrackingHandlers)
      ),
      formatMessage,
    };
  };

export default useCardSettingsContractCurrentInsuranceHook;
