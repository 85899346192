// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardSettingsContractCurrentLinkHookType } from './types/card-settings-contract-current-link.hook.type';

const useCardSettingsContractCurrentLinkHook = (): CardSettingsContractCurrentLinkHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { isAddon, status } = currentCard;

  return {
    isAddon,
    status,
  };
};

export default useCardSettingsContractCurrentLinkHook;
