// Vendors
import React from 'react';
// Components
import CardSettingsContractCurrentSectionComponent from '../section/card-settings-contract-current-section.component';
import CardSettingsContractCurrentControlLocationsComponent from './components/locations/card-settings-contract-current-control-locations.component';
import CardSettingsContractCurrentControlTransactionsComponent from './components/transactions/card-settings-contract-current-control-transactions.component';
import CardSettingsContractCurrentControlPaymentsComponent from './components/payments/card-settings-contract-current-control-payments.component';
// Constants
import { CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_SECTION_PROPS } from './constants/card-settings-contract-current-control.constants';
// Styles
import { CardSettingsContractCurrentControlComponentStyled } from './card-settings-contract-current-control.component.styled';

const CardSettingsContractCurrentControlComponent = (): React.ReactElement => (
  <CardSettingsContractCurrentSectionComponent
    {...CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_SECTION_PROPS}
  >
    <CardSettingsContractCurrentControlComponentStyled>
      <CardSettingsContractCurrentControlLocationsComponent />
      <CardSettingsContractCurrentControlPaymentsComponent />
    </CardSettingsContractCurrentControlComponentStyled>
    <CardSettingsContractCurrentControlTransactionsComponent />
  </CardSettingsContractCurrentSectionComponent>
);

export default CardSettingsContractCurrentControlComponent;
