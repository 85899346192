// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DESCRIPTION_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_MESSAGE_PROPS,
} from './constants/card-settings-contract-current-insurance-message.constants';
// Types
import { CardSettingsContractCurrentMessageComponentType } from 'containers/card-settings/views/contract/components/current/types/card-settings-contract-current-message.component.type';
// Utilities
import { getSecondaryCardLabelOrStatus } from './utils/card-settings-contract-current-insurance-message.utils';

const CardSettingsContractCurrentInsuranceMessageComponent = ({
  disabled,
  status,
}: CardSettingsContractCurrentMessageComponentType): React.ReactElement | null =>
  disabled ? (
    <MessageComponent {...CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_MESSAGE_PROPS}>
      <ParagraphComponent {...CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={getSecondaryCardLabelOrStatus(status)} />
      </ParagraphComponent>
    </MessageComponent>
  ) : null;

export default CardSettingsContractCurrentInsuranceMessageComponent;
