// Vendors
import React from 'react';
// Components
import CardArtComponent from 'components/card-art/card-art.component';
import CardSettingsContractCurrentCardCvvComponent from './components/cvv/card-settings-contract-current-card-cvv.component';
import CardSettingsContractCurrentCardExpiryComponent from './components/expiry/card-settings-contract-current-card-expiry.component';
import CardSettingsContractCurrentCardPinComponent from './components/pin/card-settings-contract-current-card-pin.component';
import CardSettingsContractCurrentCardNumberComponent from './components/number/card-settings-contract-current-card-number.component';
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
import CardStatusTagComponent from 'components/card-status-tag/card-status-tag.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_ART_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_BENEFITS_PROPS,
} from './constants/card-settings-contract-current-card.constants';
// Hooks
import CardSettingsContractCurrentLinkHook from '../../hooks/card-settings-contract-current-link.hook';
// Styles
import {
  CardSettingsContractCurrentCardComponentStyled,
  CardSettingsContractCurrentCardLayoutComponentStyled,
  CardSettingsContractCurrentCardTagComponentStyled,
} from './card-settings-contract-current-card.component.styled';
// Utilities
import { isPartnerCardOrInactive } from '../../utils/card-settings-contract-current.utils';

const CardSettingsContractCurrentCardComponent = (): React.ReactElement => (
  <CardSettingsContractCurrentCardComponentStyled>
    <CardArtComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_CARD_ART_PROPS}
      disabled={isPartnerCardOrInactive(CardSettingsContractCurrentLinkHook())}
    />
    <CardSettingsContractCurrentCardTagComponentStyled>
      <CardStatusTagComponent />
    </CardSettingsContractCurrentCardTagComponentStyled>
    <CardSettingsContractCurrentCardLayoutComponentStyled>
      <CardSettingsContractCurrentCardNumberComponent />
      <CardSettingsContractCurrentCardExpiryComponent />
      <CardSettingsContractCurrentCardPinComponent />
      <CardSettingsContractCurrentCardCvvComponent />
      <CardSettingsResumeLinkComponent {...CARD_SETTINGS_CONTRACT_CURRENT_CARD_BENEFITS_PROPS} />
    </CardSettingsContractCurrentCardLayoutComponentStyled>
  </CardSettingsContractCurrentCardComponentStyled>
);

export default CardSettingsContractCurrentCardComponent;
