// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardSettingsContractCurrentControlLocationsHookType } from './types/card-settings-contract-current-control-locations.hook.type';

const useCardSettingsContractCurrentControlLocationsHook =
  (): CardSettingsContractCurrentControlLocationsHookType => {
    const { currentCard } = AppContextConsumerHook(),
      {
        locations: { continents },
      } = currentCard?.control || {
        locations: { continents: [] },
      };

    return { continents };
  };

export default useCardSettingsContractCurrentControlLocationsHook;
