const DOMAIN = 'cobranded.services.userSettings.privateArea.cbChallengeModule.smsChallenge.',
  CHANGE_PIN_DOMAIN =
    'cobranded.services.userSettings.privateArea.cardSettings.changePin.cbChallengeModule.';

export default {
  BUTTON: `${DOMAIN}sendButton`,
  DESCRIPTION: `${DOMAIN}description`,
  ERROR: `${DOMAIN}cbInput.errorDescription`,
  ERROR_MESSAGE: `${DOMAIN}cbMessage.errorDescription`,
  HELPER: `${DOMAIN}requestNewCodeDescription`,
  LINK: `${DOMAIN}requestNewCodeLink`,
  PLACEHOLDER: `${DOMAIN}inputOtpPlaceholder`,
  SUCCESS_MESSAGE: `${DOMAIN}cbMessage.successDescription`,
  TITLE: `${DOMAIN}title`,
};

const VALIDATE_PIN = `${CHANGE_PIN_DOMAIN}validatePinButton`;

export { VALIDATE_PIN };
