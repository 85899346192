// Vendors
import styled from 'styled-components';

export const DashboardSpentComponentStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
