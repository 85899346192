// Vendors
import React from 'react';
// Components
import { MessageComponent } from '@openbank/cb-ui-commons';
import DashboardTopMessageButtonComponent from './components/button/dashboard-top-message-button.component';
import { DashboardTopMessageDescriptionComponent } from './components/description/dashboard-top-message-description.component';
// Configurations
import { getCreditLimitStatusMessageConfiguration } from './configurations/credit-limit-status-message.configuration';
// Constants
import constants from './constants/dashboad-top-message.constants';
import { DASHBOARD_TOP_MESSAGE_BUTTON_PROPS } from './components/button/constants/dashboard-top-message-button.constants';
import { limitIncreaseConstants } from './constants/credit-limit-increase-status-message.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from 'containers/financing/views/credit-limit-increase/enums/credit-limit-increase.view.enumeration';
// Hooks
import DashboardTopMessageHook from './hooks/dashboard-top-message.hook';
// Styles
import {
  DashboardTopMessageContainerComponentStyled,
  DashboardTopMessageWrapperComponentStyled,
} from './dashboard-top-message.component.styled';
// Utilities
import {
  getCreditLimitMessageCTA,
  getCreditLimitMessageType,
  getCreditLimitTrackingCTAValue,
  hasCreditLimitIncreaseStatusMessage,
} from './utils/credit-limit-status.utils';
import { isCC } from 'utils/app.utils';
// Translations
import { translations } from './translations/dashboard-top-message.translations';

const DashboardTopMessageComponent = (): React.ReactElement | null => {
  const {
    cardBlockCode,
    cardBlockDescription,
    formatMessage,
    limitIncreaseStatus,
    locale,
    nonPaid,
    offeredLimit,
    requestedLimit,
  } = DashboardTopMessageHook();
  const creditLimitMessageCTA = getCreditLimitMessageCTA(limitIncreaseStatus);
  return (
    <DashboardTopMessageContainerComponentStyled>
      {nonPaid && (
        <MessageComponent {...constants.DASHBOARD_TOP_MESSAGE_PROPS}>
          <DashboardTopMessageWrapperComponentStyled>
            <DashboardTopMessageDescriptionComponent
              {...{
                cardBlockCode,
                cardBlockDescription,
                isCC: isCC(),
                TRANSLATIONS: {
                  title: translations.TITLE,
                  description: translations.DESCRIPTION,
                  cardBlock: translations.CARD_BLOCK_CODE,
                  cardBlockDescription: translations.CARD_BLOCK_DESCRIPTION,
                },
              }}
            />
            <DashboardTopMessageButtonComponent {...DASHBOARD_TOP_MESSAGE_BUTTON_PROPS} />
          </DashboardTopMessageWrapperComponentStyled>
        </MessageComponent>
      )}
      {hasCreditLimitIncreaseStatusMessage(
        limitIncreaseStatus as CreditLimitIncreaseStepsEnumeration
      ) && (
        <MessageComponent
          type={getCreditLimitMessageType(limitIncreaseStatus)}
          {...limitIncreaseConstants.CREDIT_LIMIT_STATUS_TOP_MESSAGE_PROPS}
        >
          <DashboardTopMessageWrapperComponentStyled>
            <DashboardTopMessageDescriptionComponent
              {...getCreditLimitStatusMessageConfiguration({
                formatMessage,
                limitIncreaseStatus,
                locale,
                offeredLimit,
                requestedLimit,
              })}
            />
            {creditLimitMessageCTA && (
              <DashboardTopMessageButtonComponent
                {...limitIncreaseConstants.CREDIT_LIMIT_MESSAGE_BUTTON_PROPS}
                {...{
                  trackingLabel: getCreditLimitTrackingCTAValue(limitIncreaseStatus),
                  label: creditLimitMessageCTA,
                }}
              />
            )}
          </DashboardTopMessageWrapperComponentStyled>
        </MessageComponent>
      )}
    </DashboardTopMessageContainerComponentStyled>
  );
};

export default DashboardTopMessageComponent;
