// Translations
import { PAN_COPIED } from './translations/card-settings-contract-current-card-number.handlers.translations';
// Types
import { CardSettingsContractCurrentCardNumberBuilderReturnHandlersType } from './types/card-settings-contract-current-card-number-builder-return.handlers.type';
import { CardSettingsContractCurrentCardNumberBuilderHandlersType } from './types/card-settings-contract-current-card-number-builder.handlers.type';

const copyCardNumberLinkClickHandler = ({
  formatMessage,
  handleCopyCardNumberLinkClickTracking,
  handleCopyGenericEvent,
  pan = '',
}: CardSettingsContractCurrentCardNumberBuilderHandlersType): any => {
  handleCopyCardNumberLinkClickTracking();
  handleCopyGenericEvent({ label: formatMessage({ id: PAN_COPIED }), text: pan });
};

const CardSettingsContractCurrentCardNumberHandlers = (
  props: CardSettingsContractCurrentCardNumberBuilderHandlersType
): CardSettingsContractCurrentCardNumberBuilderReturnHandlersType => ({
  handleCopyCardNumberLinkClick: () => copyCardNumberLinkClickHandler(props),
});

export default CardSettingsContractCurrentCardNumberHandlers;
