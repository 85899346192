// Vendors
import React from 'react';
// Configurations
import { getModifyLinkConfiguration } from '../../configurations/card-settings-contract-current.configuration';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
import CardSettingsContractCurrentBillingMethodComponent from './components/method/card-settings-contract-current-billing-method.component';
import CardSettingsContractCurrentSectionComponent from '../section/card-settings-contract-current-section.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_LINK_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_CYCLE_LINK_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_CYCLE_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_STATEMENTS_PROPS,
} from './constants/card-settings-contract-current-billing.constants';
// Hooks
import useCardSettingsContractCurrentBillingHook from './hooks/card-settings-contract-current-billing.hook';
import useCardSettingsContractCurrentLinkHook from '../../hooks/card-settings-contract-current-link.hook';
// Translations
import {
  BILLING_CYCLE_DESCRIPTION,
  BILLING_CYCLE_TOOLTIP,
} from './translations/card-settings-contract-current-billing.translations';

const CardSettingsContractCurrentBillingComponent = (): React.ReactElement => {
  const { billingCyclePeriodicity: day, primaryAccountId } =
    useCardSettingsContractCurrentBillingHook();

  return (
    <CardSettingsContractCurrentSectionComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_DIVIDER_PROPS}
    >
      <CardSettingsResumeLinkComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_PROPS}
        links={getModifyLinkConfiguration({
          ...useCardSettingsContractCurrentLinkHook(),
          configuration: CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_LINK_PROPS,
        })}
        value={primaryAccountId}
      />
      <CardSettingsContractCurrentBillingMethodComponent />
      <CardSettingsResumeLinkComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_STATEMENTS_PROPS}
      />
      <CardSettingsResumeLinkComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_CYCLE_PROPS}
        links={getModifyLinkConfiguration({
          ...useCardSettingsContractCurrentLinkHook(),
          configuration: CARD_SETTINGS_CONTRACT_CURRENT_BILLING_CYCLE_LINK_PROPS,
        })}
        tooltipMessage={
          <FormattedMessageComponent id={BILLING_CYCLE_TOOLTIP} values={{ days: day }} />
        }
        value={<FormattedMessageComponent id={BILLING_CYCLE_DESCRIPTION} values={{ day }} />}
      />
    </CardSettingsContractCurrentSectionComponent>
  );
};

export default CardSettingsContractCurrentBillingComponent;
