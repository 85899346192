// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
// Translations
import {
  SECONDARY_CARD,
  STATUS_MESSAGES,
} from './translations/card-settings-contract-current-travel-message.utils.translations';
// Utilities
import { cardActive } from 'utils/card.utils';
import { CardSettingsContractCurrentLinkHookType } from 'containers/card-settings/views/contract/components/current/hooks/types/card-settings-contract-current-link.hook.type';
import { isMainCardSelected } from 'containers/card-settings/views/contract/utils/card-settings-contract.utils';

const getTravelSecondaryCardLabelOrStatus = (status: CardStatusTypesEnumeration): string =>
  cardActive(status) ? SECONDARY_CARD : STATUS_MESSAGES[status];

const cardLocked = (status: string): boolean => status === CardStatusTypesEnumeration.LOCKED;

const isPartnerCardOrInactiveAndNotLocked = ({
  isAddon,
  status,
}: CardSettingsContractCurrentLinkHookType): boolean =>
  !isMainCardSelected(isAddon) || (!cardActive(status) && !cardLocked(status));

export { isPartnerCardOrInactiveAndNotLocked, getTravelSecondaryCardLabelOrStatus };
