// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Translations
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';

const hasSomeCountryConfigured = (numberOfCountries: number): boolean => numberOfCountries > 0;

const getDescriptionOrEmpty = ({
  numberOfCountries,
  description,
}: CardControlContinentComponentType): string =>
  hasSomeCountryConfigured(numberOfCountries) ? description : '';

const getAllEnabledContinents = (continents: CardControlContinentComponentType[]): string =>
  continents?.map(getDescriptionOrEmpty)
  .join(CharactersEnum.COMMA_LINE_BREAK);

export { getAllEnabledContinents };
