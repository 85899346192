// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { LABEL } from '../translations/card-settings-contract-current-control-payments.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_PAYMENTS_PROPS = {
  label: LABEL,
};

const CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_PAYMENTS_LINK_PROPS = {
  trackingLabel: 'modifyInternetPayments',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CARD_CONTROL])
  ),
  urlState: { internetPayments: true },
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_PAYMENTS_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_PAYMENTS_LINK_PROPS,
};
