// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Constants
import {
  CARD_SETTINGS_CHANGE_3D_CODE_BUTTON_PROPS,
  CARD_SETTINGS_CHANGE_3D_CODE_CHALLENGE_PROPS,
  CARD_SETTINGS_CHANGE_3D_STEP_2_RESUME_PROPS,
} from './constants/card-settings-change-3d-step2.constants';
import { WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS } from 'constants/wizard/wizard.constants';
// Hooks
import { useCardSettingsChange3DStep2Hook } from './hooks/card-settings-change-3d-step2.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  CHANGE_CODE,
  RESUME_ITEM_LABEL,
  RESUME_ITEM_VALUE,
} from './translations/card-settings-change-3d-step2.translations';
// Utils
import { challengeFlowButtonIsDisabled } from 'utils/challenges/challenge.utils';

const CardSettingsChange3DStep2Component = (): React.ReactElement => {
  const {
    challenged,
    challengeError,
    fetching,
    handleChange3DCodeSecureButtonClick: nextClick,
    handleManageErrorChange3DSecureCodeEvent: onError,
    handleRetryChallengeButtonClick: onRetry,
    handleManageSuccessChange3DSecureCodeEvent: onSuccess,
    new3DCode: scaChallenge,
  } = useCardSettingsChange3DStep2Hook();

  return (
    <LayoutColumnFlexComponentStyled>
      <ResumeItemComponent
        {...CARD_SETTINGS_CHANGE_3D_STEP_2_RESUME_PROPS}
        label={<FormattedMessageComponent id={RESUME_ITEM_LABEL} />}
        value={<FormattedMessageComponent id={RESUME_ITEM_VALUE} />}
      />
      <ChallengeWrapperComponent
        {...{
          ...CARD_SETTINGS_CHANGE_3D_CODE_CHALLENGE_PROPS,
          challengeError,
          onError,
          onSuccess,
          onRetry,
        }}
        baseServiceBuilder={{ params: { scaChallenge } }}
      />
      <WizardStepFooterComponent
        {...WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS}
        isFooterDisabled={fetching}
        rightButtonConfiguration={{
          ...CARD_SETTINGS_CHANGE_3D_CODE_BUTTON_PROPS,
          children: <FormattedMessageComponent id={CHANGE_CODE} />,
          disabled: challengeFlowButtonIsDisabled({ challenged, challengeError, fetching }),
          loading: fetching,
          onClick: nextClick,
        }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { CardSettingsChange3DStep2Component };
