// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FETCH_DOWNLOAD_CARD_STATEMENTS_URL_SERVICE_CONFIGURATION } from './constants/card-settings-statements-table-row-download.service.constants';
// Types
import { DownloadCardSettingsStatementsUrlServiceType } from './types/card-statements-download-url.service.type';
import { CardSettingsStatementsTableRowDownloadComponentType } from '../types/card-settings-statements-table-row-download.component.type';

const fetchDownloadCardStatementDocumentService = async (
  pathParams: CardSettingsStatementsTableRowDownloadComponentType
): Promise<[DownloadCardSettingsStatementsUrlServiceType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...FETCH_DOWNLOAD_CARD_STATEMENTS_URL_SERVICE_CONFIGURATION,
    pathParams,
  });

export { fetchDownloadCardStatementDocumentService };
