import { CardControlOperativeIdsEnumeration } from 'enumerations/card-control-operative-ids.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cbCardContractDetails.cardControl.';

const OPERATIVES = {
  [CardControlOperativeIdsEnumeration.ATM]: `${DOMAIN}atmOperationsLabel`,
  [CardControlOperativeIdsEnumeration.ONLINE_STORES]: `${DOMAIN}onlinePurchasesLabel`,
  [CardControlOperativeIdsEnumeration.PHYSICAL_STORES]: `${DOMAIN}inStorePurchasesLabel`,
};

export { OPERATIVES };
