// Constants
import { CARD_SETTINGS_CHECK_3D_WIZARD_STEPS_CONFIGURATION } from './constants/card-settings-check-3d-wizard.configuration.constants';
// Configurations
import { CardSettingsCheck3dWizardConfigurationType } from './types/card-settings-check-3d-wizard.configuration.type';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  DESCRIPTION,
  TITLE,
} from '../components/feedback/translations/card-settings-check-3d-wizard-feedback.translations';
// Types
import { NavigateFunction } from 'react-router-dom';
import { CardSettingsCheck3dWizardItemConfigurationType } from './types/card-settings-check-3d-wizard-item.configuration.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import { MobileConnectorContextType } from 'providers/mobile-connector/contexts/types/mobile-connector.provider.context.type';

const getCheck3DWizardStepsConfiguration = (
  formatMessage: ({ id }: { id: string }) => string
): CardSettingsCheck3dWizardItemConfigurationType[] =>
  CARD_SETTINGS_CHECK_3D_WIZARD_STEPS_CONFIGURATION.map(
    ({ component, title }: CardSettingsCheck3dWizardItemConfigurationType) => ({
      component,
      title: formatMessage({ id: title }),
    })
  );

const getCheck3DWizardFeedbackConfiguration = ({
  onCancelHybridFlow,
}: {
  navigate: NavigateFunction;
  onCancelHybridFlow: MobileConnectorContextType['onCancelHybridFlow'];
}): CardSettingsCheck3dWizardConfigurationType => ({
  errorConfiguration: {
    description: DESCRIPTION,
    title: TITLE,
    linkClick: () => onCancelHybridFlow(generateAbsoluteRoute(RoutesEnumeration.CARD_SETTINGS)),
  },
});

export { getCheck3DWizardStepsConfiguration, getCheck3DWizardFeedbackConfiguration };
