// Vendors
import React from 'react';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_CVV_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_CVV_LINK_PROPS,
} from './constants/card-settings-contract-current-card-cvv.constants';
// Hooks
import useCardSettingsContractCurrentLinkHook from 'containers/card-settings/views/contract/components/current/hooks/card-settings-contract-current-link.hook';
// Translations
import { CVV_TOOLTIP } from './translations/card-settings-contract-current-card-cvv.translations';
// Utilities
import { isCC } from 'utils/app.utils';
import { isPartnerCardOrInactive } from 'containers/card-settings/views/contract/components/current/utils/card-settings-contract-current.utils';

const CardSettingsContractCurrentCardCvvComponent = (): React.ReactElement => {
  const { isAddon, status } = useCardSettingsContractCurrentLinkHook();
  return (
    <CardSettingsResumeLinkComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_CARD_CVV_PROPS}
      links={[
        {
          ...CARD_SETTINGS_CONTRACT_CURRENT_CARD_CVV_LINK_PROPS,
          disabled: isCC() || isPartnerCardOrInactive({ isAddon, status }),
        },
      ]}
      tooltipMessage={<FormattedMessageComponent id={CVV_TOOLTIP} />}
    />
  );
};

export default CardSettingsContractCurrentCardCvvComponent;
