// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardSettingsContractCurrentControlTransactionsHookType } from './types/card-settings-contract-current-control-transactions.hook.type';

const useCardSettingsContractCurrentControlLocationsHook =
  (): CardSettingsContractCurrentControlTransactionsHookType => {
    const { formatMessage } = useCbIntl();

    const { currentCard } = AppContextConsumerHook(),
      { isAtmCashAllowed, isInPersonPurchaseAllowed, isOnlinePurchaseAllowed } =
        currentCard?.control || {};

    return { formatMessage, isAtmCashAllowed, isInPersonPurchaseAllowed, isOnlinePurchaseAllowed };
  };

export default useCardSettingsContractCurrentControlLocationsHook;
