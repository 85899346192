// Enumerations
import { CardContractInfoSectionsEnumeration } from 'containers/card-settings/views/contract/components/current/enumerations/card-contract-info-sections.enumeration';
import { ToggleTypesEnumeration } from '@openbank/cb-ui-commons';
// Translations
import {
  RENEWAL_DATE,
  SUBSCRIPTION_STATUS,
  TITLE,
} from '../translations/card-settings-contract-current-travel.translations';

const CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_TOGGLE_PROPS = {
  label: SUBSCRIPTION_STATUS,
  type: ToggleTypesEnumeration.VERTICAL,
};

const CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DIVIDER_PROPS = {
  id: CardContractInfoSectionsEnumeration.TRAVEL,
  twoColumns: false,
  title: TITLE,
};

const CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_RESUME_PROPS = {
  label: RENEWAL_DATE,
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_RESUME_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_TOGGLE_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DIVIDER_PROPS,
};
