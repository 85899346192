// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_CHECK_LINK_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_CHANGE_LINK_PROPS,
} from './constants/card-settings-contract-current-card-pin.constants';
// Hooks
import useCardSettingsContractCurrentLinkHook from 'containers/card-settings/views/contract/components/current/hooks/card-settings-contract-current-link.hook';
// Translations
import { PIN_TOOLTIP } from './translations/card-settings-contract-current-card-pin.translations';
// Utilities
import { isCC } from 'utils/app.utils';
import { isPartnerCardOrInactive } from 'containers/card-settings/views/contract/components/current/utils/card-settings-contract-current.utils';

const CardSettingsContractCurrentCardPinComponent = (): React.ReactElement => {
  const { isAddon, status } = useCardSettingsContractCurrentLinkHook();
  return (
    <CardSettingsResumeLinkComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_PROPS}
      links={[
        {
          ...CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_CHANGE_LINK_PROPS,
          disabled: isCC() || isPartnerCardOrInactive({ isAddon, status }),
        },
        {
          ...CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_CHECK_LINK_PROPS,
          disabled: isCC() || isPartnerCardOrInactive({ isAddon, status }),
        },
      ]}
      tooltipMessage={<FormattedMessageComponent id={PIN_TOOLTIP} />}
    />
  );
};

export default CardSettingsContractCurrentCardPinComponent;
