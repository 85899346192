// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { CARD_SETTINGS_STATEMENTS_TABLE_ROW_DOWNLOAD_TRACKING_PROPS } from './constants/card-settings-statements-table-row-download.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { CardSettingsStatementsTableRowDownloadMethodsTrackingHandlersType } from './types/card-settings-statements-table-row-download.tracking.handlers.type';

const downloadCardStatementDocumentLinkClickTrackingHandler = (
  documentId: string
): TrackingLinkEventReturnType => ({
  payload: {
    ...CARD_SETTINGS_STATEMENTS_TABLE_ROW_DOWNLOAD_TRACKING_PROPS,
    eventLabel: `downloadDocument:${documentId}`,
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsStatementsTableRowDownloadTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsStatementsTableRowDownloadMethodsTrackingHandlersType => ({
  handleDownloadCardStatementDocumentLinkClickTracking: (documentId: string) =>
    track(downloadCardStatementDocumentLinkClickTrackingHandler(documentId)),
});

export { CardSettingsStatementsTableRowDownloadTrackingHandlers };
