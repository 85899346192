// Vendors
import styled from 'styled-components';
// Styles
import { CardSettingsContractCurrentSectionComponentStyled } from '../section/card-settings-contract-current-section.component.styled';

const CardSettingsContractCurrentTravelComponentStyled = styled(
  CardSettingsContractCurrentSectionComponentStyled
)`
  padding: 0;
`;

export { CardSettingsContractCurrentTravelComponentStyled };
