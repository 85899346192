// Vendors
import styled from 'styled-components';

const DashboardLastSessionComponentStyled = styled.footer`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;
`;
DashboardLastSessionComponentStyled.displayName = 'DashboardLastSessionComponentStyled';

export { DashboardLastSessionComponentStyled };
