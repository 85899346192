// Vendors
import React from 'react';
// Components
import DashboardSpentBottomComponent from './components/bottom/dashboard-spent-bottom.component';
import DashboardSpentTopComponent from './components/top/dashboard-spent-top.component';
// Constants
import constants from './constants/dashboard-spent.constants';
// Hooks
import DashboardSpentHook from './hooks/dashboard-spent.hook';
// Styles
import { AppCardSectionComponentStyled } from 'styles/app.styled';
import { DashboardWrapperCardContainerStyled } from 'containers/dashboard/dashboard.container.styled';
import { DashboardSpentComponentStyled } from './dashboard-spent.component.styled';
// Types
import { DashboardSpentComponentType } from './types/dashboard-spent.component.type';
// Utils
import { isValidPercentage } from './utils/dashboard-spent.component.utils';

const DashboardSpentComponent = ({
  testId = constants.DASHBOARD_SPENT_DEFAULT_TEST_ID,
}: DashboardSpentComponentType): React.ReactElement | null => {
  const {
    currentPercentage = 0,
    expenses,
    isMobile,
    percentageDifference = 0,
    previousPercentage = 0,
    total = 0,
  } = DashboardSpentHook();

  return isValidPercentage(percentageDifference) ? (
    <AppCardSectionComponentStyled>
      <DashboardWrapperCardContainerStyled {...{ testId }}>
        <DashboardSpentComponentStyled>
          <DashboardSpentTopComponent
            {...{
              currentPercentage,
              expenses,
              isMobile,
              previousPercentage,
              percentageDifference,
              total,
            }}
          />
          <DashboardSpentBottomComponent {...{ expenses, isMobile, total }} />
        </DashboardSpentComponentStyled>
      </DashboardWrapperCardContainerStyled>
    </AppCardSectionComponentStyled>
  ) : null;
};

export default DashboardSpentComponent;
