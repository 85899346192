// Vendors
import React from 'react';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_NUMBER_LINK_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_NUMBER_PROPS,
} from './constants/card-settings-contract-current-card-number.constants';
// Hooks
import useCardSettingsContractCurrentCardNumberHook from './hooks/card-settings-contract-current-card-number.hook';

const CardSettingsContractCurrentCardNumberComponent = (): React.ReactElement => {
  const { handleCopyCardNumberLinkClick, pan } = useCardSettingsContractCurrentCardNumberHook();

  return (
    <CardSettingsResumeLinkComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_CARD_NUMBER_PROPS}
      links={[
        {
          ...CARD_SETTINGS_CONTRACT_CURRENT_CARD_NUMBER_LINK_PROPS,
          onClick: handleCopyCardNumberLinkClick,
        },
      ]}
      value={pan}
    />
  );
};

export default CardSettingsContractCurrentCardNumberComponent;
