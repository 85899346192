// Translations
import { RENOVATION_DATE } from '../translations/card-settings-contract-current-travel-date.translations';

const CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DATE_PROPS = {
  label: RENOVATION_DATE,
  testing: {
    labelId: 'card-settings-contract-current-travel-date-label',
    valueId: 'card-settings-contract-current-travel-date-value',
  },
};

export { CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DATE_PROPS };
