// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardSettingsContractCurrentControlLocationsHookType } from './types/card-settings-contract-current-control-locations.hook.type';

const useCardSettingsContractCurrentControlPaymentsHook =
  (): CardSettingsContractCurrentControlLocationsHookType => {
    const { currentCard } = AppContextConsumerHook(),
      { isAllowPaymentsNonSecStore } = currentCard?.control || {};

    return { isAllowPaymentsNonSecStore };
  };

export default useCardSettingsContractCurrentControlPaymentsHook;
