// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Events handlers
import { CardSettingsContractCurrentTravelHandlers } from '../handlers/card-settings-contract-current-travel.handlers';
import { CardSettingsContractCurrentTravelTrackingHandlers } from '../handlers/card-settings-contract-current-travel.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsContractCurrentTravelHookType } from './types/card-settings-contract-current-travel.hook.type';

const useCardSettingsContractCurrentTravelHook = (): CardSettingsContractCurrentTravelHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { activated } = currentCard?.travelPlus || {};

  const { formatMessage } = useCbIntl();

  const [modalShow, setModalShow] = useState<any>(false);
  const [checked, setChecked] = useState<boolean>(!!activated);

  const { handleToggleCardContractTravelPlusModalEvent } =
    CardSettingsContractCurrentTravelHandlers({
      ...AppTrackingHook(CardSettingsContractCurrentTravelTrackingHandlers),
      activated,
      setChecked,
      setModalShow,
    });

  return {
    activated,
    checked,
    formatMessage,
    handleToggleCardContractTravelPlusModalEvent,
    modalShow,
  };
};

export { useCardSettingsContractCurrentTravelHook };
