// Vendors
import React from 'react';
// Components
import WizardComponent from 'components/wizard/wizard.component';
// Configurations
import {
  getCheck3DWizardStepsConfiguration,
  getCheck3DWizardFeedbackConfiguration,
} from './configurations/card-settings-check-3d-wizard.configuration';
// Contexts
import { CardSettingsCheck3DWizardContextConsumerHOC } from './contexts/card-settings-check-3d-wizard.context';
// Hooks
import useCardSettingsCheck3dWizardHook from './hooks/card-settings-check-3d-wizard.hook';
// Types
import { CardSettingsCheck3dWizardProps } from './types/card-settings-check-3d-wizard.component.type';

const CardSettingsCheck3dWizardComponent = ({
  currentStep,
}: CardSettingsCheck3dWizardProps): React.ReactElement<CardSettingsCheck3dWizardProps> => {
  const { formatMessage, navigate, onCancelHybridFlow } = useCardSettingsCheck3dWizardHook();

  return (
    <WizardComponent
      feedbackConfiguration={getCheck3DWizardFeedbackConfiguration({
        navigate,
        onCancelHybridFlow,
      })}
      steps={getCheck3DWizardStepsConfiguration(formatMessage)}
      currentStep={currentStep}
    />
  );
};

export { CardSettingsCheck3dWizardComponent };

export default CardSettingsCheck3DWizardContextConsumerHOC(CardSettingsCheck3dWizardComponent);
