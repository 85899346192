// Vendors
import React from 'react';
// Components
import { TitleHeaderComponent } from '@openbank/cb-ui-commons';
import CardStatusComponent from 'components/card-status/card-status.component';
import CardSettingsContractCurrentBillingComponent from './components/billing/card-settings-contract-current-billing.component';
import CardSettingsContractCurrentCardComponent from './components/card/card-settings-contract-current-card.component';
import CardSettingsContractCurrentCreditComponent from './components/credit/card-settings-contract-current-credit.component';
import CardSettingsContractCurrentContractComponent from './components/contract/card-settings-contract-current-contract.component';
import CardSettingsContractCurrentControlComponent from './components/control/card-settings-contract-current-control.component';
import CardSettingsContractCurrentInsuranceComponent from './components/insurance/card-settings-contract-current-insurance.component';
import CardSettingsContractCurrentTravelComponent from './components/travel/card-settings-contract-current-travel.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { CARD_SETTINGS_CONTRACT_CURRENT_TITLE_PROPS } from './constants/card-settings-contract-current.constants';
// Hooks
import useCardSettingsContractCurrentHook from './hooks/card-settings-contract-current.hook';
// Styles
import {
  AppCardSectionComponentStyled,
  AppCardVerticalSectionComponentStyled,
} from 'styles/app.styled';
import {
  CardSettingsContractCurrentComponentStyled,
  CardSettingsContractCurrentTitleComponentStyled,
} from './card-settings-contract-current.component.styled';
// Utilities
import { getCurrentContractCardTitle } from './utils/card-settings-contract-current.utils';

const CardSettingsContractCurrentComponent = (): React.ReactElement => {
  const { isAddon } = useCardSettingsContractCurrentHook();

  return (
    <AppCardSectionComponentStyled>
      <AppCardVerticalSectionComponentStyled>
        <CardSettingsContractCurrentComponentStyled>
          <CardSettingsContractCurrentTitleComponentStyled>
            <TitleHeaderComponent {...CARD_SETTINGS_CONTRACT_CURRENT_TITLE_PROPS}>
              <FormattedMessageComponent id={getCurrentContractCardTitle(isAddon)} />
            </TitleHeaderComponent>
          </CardSettingsContractCurrentTitleComponentStyled>
          <CardStatusComponent />
          <CardSettingsContractCurrentCardComponent />
          <CardSettingsContractCurrentCreditComponent />
          <CardSettingsContractCurrentBillingComponent />
          <CardSettingsContractCurrentContractComponent />
          <CardSettingsContractCurrentControlComponent />
          <CardSettingsContractCurrentInsuranceComponent />
          <CardSettingsContractCurrentTravelComponent />
        </CardSettingsContractCurrentComponentStyled>
      </AppCardVerticalSectionComponentStyled>
    </AppCardSectionComponentStyled>
  );
};

export default CardSettingsContractCurrentComponent;
