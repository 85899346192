const DOMAIN_DASHBOARD_POINTS = 'cobranded.services.userSettings.privateArea.dashboard.cbPoints.';
const DOMAIN_LINE_GRAPH_DESCRIPTIONS = 'cobranded.services.userSettings.privateArea.dashboard.lineGraph.';

export default {
  KNOW_MORE: `${DOMAIN_DASHBOARD_POINTS}knowMoreLink`,
  LINE_GRAPH_DESCRIPTIONS: {
    AVAILABLE_POINTS: `${DOMAIN_LINE_GRAPH_DESCRIPTIONS}availablePoints`,
    BALANCE: `${DOMAIN_LINE_GRAPH_DESCRIPTIONS}balance`,
    COLLECTED_POINTS: `${DOMAIN_LINE_GRAPH_DESCRIPTIONS}collectedPoints`,
    REDEEMED_POINTS: `${DOMAIN_LINE_GRAPH_DESCRIPTIONS}redeemedPoints`,
  },
  TITLE: `${DOMAIN_DASHBOARD_POINTS}title`,
};
