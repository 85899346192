// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsContractCurrentBillingMethodValueHookType } from './types/card-settings-contract-current-billing-method-value.hook.type';

const useCardSettingsContractCurrentBillingMethodValueHook =
  (): CardSettingsContractCurrentBillingMethodValueHookType => {
    const { currentCard } = AppContextConsumerHook(),
      { currencyCode } = currentCard,
      { fixedPaymentAmount = 0, paymentMethodCode = CharactersEnum.NO_VALUE } =
        currentCard?.creditDetails || {};

    return {
      currencyCode,
      fixedPaymentAmount,
      paymentMethodCode,
    };
  };

export default useCardSettingsContractCurrentBillingMethodValueHook;
