const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cbCardContractDetails.billing.';

const BILLING_ACCOUNT = `${DOMAIN}billingAccountLabel`;
const BILLING_CYCLE_LABEL = `${DOMAIN}billingCycleLabel`;
const BILLING_CYCLE_TOOLTIP = `${DOMAIN}cbTooltip.billingCycleDescription`;
const BILLING_CYCLE_DESCRIPTION = `${DOMAIN}billingCycleDescription`;
const CARD_STATEMENTS_LINK = `${DOMAIN}viewCardStatementsLink`;
const CARD_STATEMENTS = `${DOMAIN}cardStatementsLabel`;
const TITLE = `${DOMAIN}title`;

export {
  BILLING_ACCOUNT,
  BILLING_CYCLE_LABEL,
  BILLING_CYCLE_TOOLTIP,
  BILLING_CYCLE_DESCRIPTION,
  CARD_STATEMENTS_LINK,
  CARD_STATEMENTS,
  TITLE,
};
