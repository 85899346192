// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardSettingsContractCurrentCardExpiryHookType } from './types/card-settings-contract-current-card-expiry.hook.type';

const useCardSettingsContractCurrentCardExpiryHook =
  (): CardSettingsContractCurrentCardExpiryHookType => {
    const { formatDate } = useCbIntl();

    const { currentCard } = AppContextConsumerHook(),
      { expirationDate } = currentCard || {};

    return {
      formatDate,
      expirationDate,
    };
  };

export default useCardSettingsContractCurrentCardExpiryHook;
