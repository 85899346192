// Resolvers
import { fetchDownloadCardStatementDocumentResolver } from '../resolvers/card-settings-statements-table-row-download.resolver';
// Translations
import { ERROR_DOWNLOAD } from './translations/card-settings-statements-table-row-download.handlers.translations';
// Types
import {
  CardSettingsStatementsTableRowDownloadBuilderHandlersType,
  CardSettingsStatementsTableRowDownloadMethodsHandlersType,
} from './types/card-settings-statements-table-row-download.handlers.type';
// Utilities
import { setupToastConfiguration } from 'components/toast/utils/toast.utils';

const downloadCardStatementDocumentLinkClickHandler = async ({
  handleDownloadCardStatementDocumentLinkClickTracking,
  setToastConfiguration,
  setFetching,
  ...documentProps
}: CardSettingsStatementsTableRowDownloadBuilderHandlersType): Promise<void> => {
  setFetching(true);
  handleDownloadCardStatementDocumentLinkClickTracking(documentProps?.documentId);

  const [document] = await fetchDownloadCardStatementDocumentResolver(documentProps);

  document
    ? window.open(document?.documentUri)
    : setToastConfiguration(setupToastConfiguration({ error: true, description: ERROR_DOWNLOAD }));
  setFetching(false);
};

const CardSettingsStatementsTableRowDownloadHandlers = (
  props: CardSettingsStatementsTableRowDownloadBuilderHandlersType
): CardSettingsStatementsTableRowDownloadMethodsHandlersType => ({
  handleDownloadCardStatementDocumentLinkClick: () =>
    downloadCardStatementDocumentLinkClickHandler(props),
});

export { CardSettingsStatementsTableRowDownloadHandlers };
