// Vendors
import React from 'react';
// Components
import { SectionDividerComponent } from '@openbank/cb-ui-commons';
import { CardSettingsDividerTitleComponent } from 'containers/card-settings/views/landing/components/divider-title/card-settings-divider-title.component';
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import {
  CARD_SETTINGS_BENEFITS_INSURANCES_PROPS,
  CARD_SETTINGS_BENEFITS_NEW_PROPS,
  CARD_SETTINGS_BENEFITS_SECONDARY_PROPS,
  CARD_SETTINGS_BENEFITS_LAYOUT_PROPS,
  CARD_SETTINGS_DETAILS_DIVIDER_PROPS,
} from './constants/card-settings-benefits.constants';
// Styles
import { CardSettingSectionDividerComponentStyled } from '../../card-settings-landing.container.styled';
// Translations
import { TITLE } from './translations/card-settings-benefits.translations';

const CardSettingsBenefitsComponent = (): React.ReactElement => (
  <SectionDividerComponent
    {...CARD_SETTINGS_DETAILS_DIVIDER_PROPS}
    title={<CardSettingsDividerTitleComponent title={TITLE} />}
  >
    <CardSettingSectionDividerComponentStyled {...CARD_SETTINGS_BENEFITS_LAYOUT_PROPS}>
      <CardSettingsResumeLinkComponent {...CARD_SETTINGS_BENEFITS_NEW_PROPS} />
      <CardSettingsResumeLinkComponent {...CARD_SETTINGS_BENEFITS_SECONDARY_PROPS} />
      <CardSettingsResumeLinkComponent {...CARD_SETTINGS_BENEFITS_INSURANCES_PROPS} />
    </CardSettingSectionDividerComponentStyled>
  </SectionDividerComponent>
);

export { CardSettingsBenefitsComponent };
