// Vendors
import styled, { css } from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsContractCurrentSectionComponentStyled = styled.div<{ twoColumns?: boolean }>`
  align-items: flex-start;
  flex: 1;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces.spacingM}
    calc(${({ theme }) => theme.spaces.spacingXxxxl} + ${({ theme }) => theme.spaces.spacingS})
    ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;
  ${({ twoColumns = true }) =>
    twoColumns &&
    css`
      display: grid;
      column-gap: ${({ theme }) => theme.spaces.spacingXl};
      grid-template-columns: repeat(2, 1fr);
      row-gap: ${({ theme }) => theme.spaces.spacingL};
    `};

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingM};
    padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS} 0;
    justify-content: space-around;
  }
`;

export { CardSettingsContractCurrentSectionComponentStyled };
