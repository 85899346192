// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cbCardContractDetails.travel.cbMessage.';

const SECONDARY_CARD = `${DOMAIN}secondaryCardDescription`;
const STATUS_MESSAGES = {
  [CardStatusTypesEnumeration.BLOCKED]: `${DOMAIN}blockedDescription`,
  [CardStatusTypesEnumeration.CANCELLED]: `${DOMAIN}blockedCancelledDescription`,
  [CardStatusTypesEnumeration.INACTIVE]: `${DOMAIN}pendingActivationDescription`,
  [CardStatusTypesEnumeration.TEMPORARILY_BLOCKED]: `${DOMAIN}temporaryBlockedDescription`,
};

export { SECONDARY_CARD, STATUS_MESSAGES };
