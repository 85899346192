// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsContractCurrentInsuranceBuilderReturnTrackingHandlersType } from './types/card-settings-contract-current-insurance-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const changePPiStatusToggleChangeEventTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'updateProtectionPaymentInsuranceToggle',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsContractCurrentInsuranceTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsContractCurrentInsuranceBuilderReturnTrackingHandlersType => ({
  handleChangePPiStatusToggleChangeEventTracking: () =>
    track(changePPiStatusToggleChangeEventTracking()),
});

export default CardSettingsContractCurrentInsuranceTrackingHandlers;
