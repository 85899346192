// Enumerations
import { StatementStatusEnumeration } from '../../row/components/download/enumerations/statement-status.enumeration';
// Types
import { CardStatementsType } from 'types/card-statements-type';
import { CardSettingsStatementsMessageHookType } from '../hooks/types/card-settings-statements-message.hook.type';

const getAllUnpaidCardSettingsStatements = (
  statements: CardSettingsStatementsMessageHookType['elements']
): CardSettingsStatementsMessageHookType['elements'] =>
  statements?.filter(
    ({ statementStatus }: CardStatementsType) =>
      statementStatus === StatementStatusEnumeration.UNPAID
  ) || [];

const hasUnpaidCardSettingsStatements = (
  statements: CardSettingsStatementsMessageHookType['elements']
): boolean => getAllUnpaidCardSettingsStatements(statements).length > 0;

const getTotalUnpaidAmount = (
  statements: CardSettingsStatementsMessageHookType['elements']
): number =>
  getAllUnpaidCardSettingsStatements(statements).reduce(
    (accumulated: number, { composeData }: CardStatementsType) =>
      (accumulated += composeData?.paymentAmount),
    0
  );

export { getTotalUnpaidAmount, hasUnpaidCardSettingsStatements };
