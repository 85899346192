// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import CardSettingsCheck3dConfirmationFooterHandlers from '../handlers/card-settings-check-3d-confirmation-footer.handlers';
import CardSettingsCheck3dConfirmationFooterTrackingHandlers from '../handlers/card-settings-check-3d-confirmation-footer.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsCheck3dConfirmationFooterBuilderReturnHandlersType } from '../handlers/types/card-settings-check-3d-confirmation-footer.handlers.type';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const useCardSettingsCheck3dConfirmationFooterHook =
  (): CardSettingsCheck3dConfirmationFooterBuilderReturnHandlersType => {
    const { onSuccessHybridFlow } = MobileConnectorContextConsumerHook();

    return CardSettingsCheck3dConfirmationFooterHandlers({
      ...AppTrackingHook(CardSettingsCheck3dConfirmationFooterTrackingHandlers),
      onSuccessHybridFlow,
      navigate: useNavigate(),
    });
  };

export default useCardSettingsCheck3dConfirmationFooterHook;
