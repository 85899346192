// Vendors
import React from 'react';
// Configuration
import { getModifyLinkConfiguration } from 'containers/card-settings/views/contract/components/current/configurations/card-settings-contract-current.configuration';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
import CardSettingsContractCurrentBillingMethodValueComponent from './components/value/card-settings-contract-current-billing-method-value.component';
import CardSettingsContractCurrentBillingMethodTooltipComponent from './components/tooltip/card-settings-contract-current-billing-method-tooltip.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_LINK_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_PROPS,
} from './constants/card-settings-contract-current-billing-method.constants';
// Hooks
import useCardSettingsContractCurrentLinkHook from 'containers/card-settings/views/contract/components/current/hooks/card-settings-contract-current-link.hook';

const CardSettingsContractCurrentBillingMethodComponent = (): React.ReactElement => (
  <CardSettingsResumeLinkComponent
    {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_PROPS}
    links={getModifyLinkConfiguration({
      ...useCardSettingsContractCurrentLinkHook(),
      configuration: CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_LINK_PROPS,
    })}
    tooltipMessage={<CardSettingsContractCurrentBillingMethodTooltipComponent />}
    value={<CardSettingsContractCurrentBillingMethodValueComponent />}
  />
);

export default CardSettingsContractCurrentBillingMethodComponent;
