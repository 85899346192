// Components
import { CardSettingsChange3DStep1Component } from '../components/step1/card-settings-change-3d-step1.component';
import { CardSettingsChange3DStep2Component } from '../components/step2/card-settings-change-3d-step2.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { TITLE as STEP_1_TITLE } from '../components/step1/translations/card-settings-change-3d-step1.translations';
import { TITLE as STEP_2_TITLE } from '../components/step2/translations/card-settings-change-3d-step2.translations';
import {
  ERROR_TITLE,
  GO_TO_SETTINGS,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
} from '../translations/card-settings-change-3d-feedback.translations';

const CARD_SETTINGS_CHANGE_3D_WIZARD_STEPS = [
  {
    component: CardSettingsChange3DStep1Component,
    title: STEP_1_TITLE,
  },
  {
    component: CardSettingsChange3DStep2Component,
    title: STEP_2_TITLE,
  },
];

const CARD_SETTINGS_CHANGE_3D_WIZARD_FEEDBACK_SUCCESS = {
  description: SUCCESS_DESCRIPTION,
  linkLabel: GO_TO_SETTINGS,
  title: SUCCESS_TITLE,
  exitRoute: RoutesEnumeration.CARD_SETTINGS,
};

const CARD_SETTINGS_CHANGE_3D_WIZARD_FEEDBACK_ERROR = {
  title: ERROR_TITLE,
  exitRoute: RoutesEnumeration.CARD_SETTINGS,
};

export {
  CARD_SETTINGS_CHANGE_3D_WIZARD_STEPS,
  CARD_SETTINGS_CHANGE_3D_WIZARD_FEEDBACK_SUCCESS,
  CARD_SETTINGS_CHANGE_3D_WIZARD_FEEDBACK_ERROR,
};
