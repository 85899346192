// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
// Translations
import {
  SECONDARY_CARD,
  STATUS_MESSAGES,
} from './translations/card-settings-contract-current-insurance-message.utils.translations';
// Utilities
import { cardActive } from 'utils/card.utils';

const getSecondaryCardLabelOrStatus = (status: CardStatusTypesEnumeration): string =>
  cardActive(status) ? SECONDARY_CARD : STATUS_MESSAGES[status];

export { getSecondaryCardLabelOrStatus };
