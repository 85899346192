// Services
import { fetchDownloadCardStatementDocumentService } from '../services/card-settings-statements-table-row-download.service';
// Types
import { DownloadCardSettingsStatementsUrlServiceType } from '../services/types/card-statements-download-url.service.type';
import { CardSettingsStatementsTableRowDownloadComponentType } from '../types/card-settings-statements-table-row-download.component.type';

const fetchDownloadCardStatementDocumentResolver = async (
  props: CardSettingsStatementsTableRowDownloadComponentType
): Promise<[DownloadCardSettingsStatementsUrlServiceType | Record<string, any>] | [null, true]> => {
  const [response] = await fetchDownloadCardStatementDocumentService(props);

  return response?.documentUri ? [response] : [null, true];
};

export { fetchDownloadCardStatementDocumentResolver };
