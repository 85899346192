// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsContractCurrentBillingHookType } from 'containers/card-settings/views/contract/components/current/components/billing/hooks/types/card-settings-contract-current-billing.hook.type';

const useCardSettingsContractCurrentBillingHook =
  (): CardSettingsContractCurrentBillingHookType => {
    const { currentCard } = AppContextConsumerHook(),
      { billingCyclePeriodicity = CharactersEnum.NO_VALUE } = currentCard?.creditDetails || {},
      { primaryAccountId = CharactersEnum.NO_VALUE } = currentCard?.contractInfo || {};

    return {
      billingCyclePeriodicity,
      primaryAccountId,
    };
  };

export default useCardSettingsContractCurrentBillingHook;
