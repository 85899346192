// Vendors
import React from 'react';
// Components
import { ToggleComponent } from '@openbank/cb-ui-commons';
import CardSettingsContractCurrentSectionComponent from '../section/card-settings-contract-current-section.component';
import CardSettingsContractCurrentInsuranceMessageComponent from './components/message/card-settings-contract-current-insurance-message.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_TOGGLE_PROPS,
} from './constants/card-settings-contract-current-insurance.constants';
import { CardSettingsContractCurrentMessageComponentStyled } from '../../card-settings-contract-current.component.styled';
// Hooks
import useCardSettingsContractCurrentLinkHook from 'containers/card-settings/views/contract/components/current/hooks/card-settings-contract-current-link.hook';
import useCardSettingsContractCurrentInsuranceHook from './hooks/card-settings-contract-current-insurance.hook';
// Translations
import { INSURANCE_STATUS } from './translations/card-settings-contract-current-insurance.translations';
import { OFF, ON } from 'translations/toggle.translations';
// Utilities
import { isPartnerCardOrInactive } from './utils/card-settings-contract-current-insurance.utils';

const CardSettingsContractCurrentInsuranceComponent = (): React.ReactElement => {
  const { formatMessage, handleChangePPiStatusToggleChangeEvent } =
      useCardSettingsContractCurrentInsuranceHook(),
    { isAddon, status } = useCardSettingsContractCurrentLinkHook(),
    disabled = isPartnerCardOrInactive({ isAddon, status });

  return (
    <CardSettingsContractCurrentSectionComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DIVIDER_PROPS}
    >
      <CardSettingsContractCurrentMessageComponentStyled>
        <ToggleComponent
          {...{ ...CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_TOGGLE_PROPS, disabled }}
          label={<FormattedMessageComponent id={INSURANCE_STATUS} />}
          labelOff={formatMessage({ id: OFF }).toUpperCase()}
          labelOn={formatMessage({ id: ON }).toUpperCase()}
          onChange={handleChangePPiStatusToggleChangeEvent}
        />
        <CardSettingsContractCurrentInsuranceMessageComponent {...{ disabled, status }} />
      </CardSettingsContractCurrentMessageComponentStyled>
    </CardSettingsContractCurrentSectionComponent>
  );
};

export default CardSettingsContractCurrentInsuranceComponent;
