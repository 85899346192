// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  CHANGE_LINK,
  CHECK_LINK,
  PIN,
} from '../translations/card-settings-contract-current-card-pin.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_PROPS = {
  label: PIN,
};

const CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_CHECK_LINK_PROPS = {
  label: CHECK_LINK,
  trackingLabel: 'checkPin',
  testId: 'card-settings-contract-current-card-pin-check-link',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.PIN])
  ),
};

const CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_CHANGE_LINK_PROPS = {
  label: CHANGE_LINK,
  trackingLabel: 'changePin',
  testId: 'card-settings-contract-current-card-pin-change-link',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CHANGE_PIN])
  ),
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_CHECK_LINK_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_PIN_CHANGE_LINK_PROPS,
};
