// Vendors
import { messageConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_MESSAGE_PROPS = {
  type: messageConstants.TYPES.ERROR,
  testId: 'card-settings-contract-current-travel-message',
};

const CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DESCRIPTION_PROPS = {
  testId: 'card-settings-contract-current-travel-description',
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DESCRIPTION_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_MESSAGE_PROPS,
};
