// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';

export default {
  CARD_SETTINGS_TOGGLE_STATUS_PROPS: {
    id: 'turnOnOffCard',
    testId: 'card-settings-toggle-card-status-tag',
  },
  CARD_SETTINGS_TOGGLE_TRAVEL_PROPS: {
    id: 'updateTravelPlusCredit',
    testId: 'card-settings-toggle-travel',
  },
  CARD_SETTINGS_TOGGLE_VERTICAL_DIVIDER_PROPS: {
    color: AmazonTheme.default.colors.backgroundTertiaryD,
  },
  INVALID_STATUS: [
    CardStatusTypesEnumeration.BLOCKED,
    CardStatusTypesEnumeration.CANCELLED,
    CardStatusTypesEnumeration.INACTIVE,
    CardStatusTypesEnumeration.TEMPORARILY_BLOCKED,
  ],
};
