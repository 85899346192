// Vendors
import React from 'react';
// Configurations
import { getModifyLinkConfiguration } from 'containers/card-settings/views/contract/components/current/configurations/card-settings-contract-current.configuration';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_LINK_PROPS,
} from 'containers/card-settings/views/contract/components/current/components/control/components/transactions/constants/card-settings-contract-current-control-transactions.constants';
// Hooks
import useCardSettingsContractCurrentLinkHook from 'containers/card-settings/views/contract/components/current/hooks/card-settings-contract-current-link.hook';
import useCardSettingsContractCurrentControlLocationsHook from 'containers/card-settings/views/contract/components/current/components/control/components/transactions/hooks/card-settings-contract-current-control-transactions.hook';
// Utilities
import { getAllEnabledCardOperations } from './utils/card-settings-contract-current-control-transactions.utils';

const CardSettingsContractCurrentControlTransactionsComponent = (): React.ReactElement => (
  <CardSettingsResumeLinkComponent
    {...CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_PROPS}
    links={getModifyLinkConfiguration({
      ...useCardSettingsContractCurrentLinkHook(),
      configuration: CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_LINK_PROPS,
    })}
    value={getAllEnabledCardOperations(useCardSettingsContractCurrentControlLocationsHook())}
  />
);

export default CardSettingsContractCurrentControlTransactionsComponent;
