// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DESCRIPTION_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_MESSAGE_PROPS,
} from './constants/card-settings-contract-current-travel-message.constants';
// Hooks
import useCardSettingsContractCurrentLinkHook from 'containers/card-settings/views/contract/components/current/hooks/card-settings-contract-current-link.hook';
// Utilities
import {
  getTravelSecondaryCardLabelOrStatus,
  isPartnerCardOrInactiveAndNotLocked,
} from './utils/card-settings-contract-current-travel-message.utils';

const CardSettingsContractCurrentTravelMessageComponent = (): React.ReactElement | null => {
  const { isAddon, status } = useCardSettingsContractCurrentLinkHook();

  return isPartnerCardOrInactiveAndNotLocked({ isAddon, status }) ? (
    <MessageComponent {...CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_MESSAGE_PROPS}>
      <ParagraphComponent {...CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={getTravelSecondaryCardLabelOrStatus(status)} />
      </ParagraphComponent>
    </MessageComponent>
  ) : null;
};

export { CardSettingsContractCurrentTravelMessageComponent };
