// Vendors
import { CharactersEnum, MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Translations
import {
  TRAVEL_OFF,
  TRAVEL_ON,
} from './translations/card-settings-contract-current-travel-date.utils.translations';
// Types
import { CardSettingsContractCurrentTravelDateHookType } from '../hooks/types/card-settings-contract-current-travel-date.hook.type';

const getRenovationDateTooltipLabel = (
  activated: CardSettingsContractCurrentTravelDateHookType['activated']
): string => (activated ? TRAVEL_ON : TRAVEL_OFF);

const getTravelPlusRenovationDate = ({
  activated,
  formatDate,
  renovationDate,
}: Pick<
  CardSettingsContractCurrentTravelDateHookType,
  'activated' | 'formatDate' | 'renovationDate'
>): string =>
  activated && renovationDate
    ? formatDate(renovationDate, MiscellaneousDateConstants.FORMAT_DD_MM_YYYY)
    : CharactersEnum.NO_VALUE;

export { getRenovationDateTooltipLabel, getTravelPlusRenovationDate };
