// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Events handlers
import CardSettingsContractCurrentCardNumberHandlers from '../handlers/card-settings-contract-current-card-number.handlers';
import CardSettingsContractCurrentCardNumberTrackingHandlers from '../handlers/card-settings-contract-current-card-number.tracking.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import useCopyHook from 'hooks/copy.hook';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsContractCurrentCardNumberHookType } from './types/card-settings-contract-current-card-number.hook.type';

const useCardSettingsContractCurrentCardNumberHook =
  (): CardSettingsContractCurrentCardNumberHookType => {
    const { formatMessage } = useCbIntl();

    const { handleCopyGenericEvent } = useCopyHook();

    const { currentCard } = AppContextConsumerHook(),
      { pan } = currentCard || {};

    return {
      ...CardSettingsContractCurrentCardNumberHandlers({
        ...AppTrackingHook(CardSettingsContractCurrentCardNumberTrackingHandlers),
        handleCopyGenericEvent,
        formatMessage,
        pan,
      }),
      pan,
    };
  };

export default useCardSettingsContractCurrentCardNumberHook;
