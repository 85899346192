// Constants
import { THREE } from '../constants/card-settings-change-3d-step1.constants';
// Types
import {
  CardSettingsChange3dStep1DisabledUtilsType,
  CardSettingsChange3dStep1EqualsUtilsType,
  CardSettingsChange3dStep1LengthUtilsType,
  CardSettingsChange3dStep1MatchesUtilsType,
} from './types/card-settings-change-3d-step1.utils.type';
// Utilities
import {
  checkNoAscendingDescendingSequences,
  checkNoContain3OrMoreConsecutiveAndEqualsNumbers,
  checkNoMatchCustomerBirthDate,
  checkHasFourNumbersOnly,
} from 'utils/constraints/constraints.utils';

const hasMoreThanThreeNumbers = (value: string): boolean => value.length > THREE;

const isCodeEmpty = (code: string): boolean => Boolean(code.length);

const code3DSecureMatchesConstraints = ({
  new3DCode,
  dateOfBirth,
}: CardSettingsChange3dStep1MatchesUtilsType): boolean =>
  [
    checkNoAscendingDescendingSequences(new3DCode),
    checkNoContain3OrMoreConsecutiveAndEqualsNumbers(new3DCode),
    checkNoMatchCustomerBirthDate({ dateOfBirth, value: new3DCode }),
    checkHasFourNumbersOnly(new3DCode),
  ].every((constraint: boolean) => constraint);

const codes3DSecureAreNotEquals = ({
  new3DCode,
  repeated3DCode,
}: CardSettingsChange3dStep1EqualsUtilsType): boolean =>
  Boolean(new3DCode && repeated3DCode && new3DCode !== repeated3DCode);

const codeHasCorrectLengthAndNoEqual = ({
  code,
  otherCode,
}: CardSettingsChange3dStep1LengthUtilsType): boolean =>
  checkHasFourNumbersOnly(code) &&
  codes3DSecureAreNotEquals({ new3DCode: code, repeated3DCode: otherCode });

const change3DCodeStep1ButtonIsDisabled = ({
  dateOfBirth,
  new3DCode,
  repeated3DCode,
}: CardSettingsChange3dStep1DisabledUtilsType): boolean =>
  !isCodeEmpty(new3DCode) ||
  !isCodeEmpty(repeated3DCode) ||
  !code3DSecureMatchesConstraints({ new3DCode, dateOfBirth }) ||
  codes3DSecureAreNotEquals({ new3DCode, repeated3DCode });

export {
  change3DCodeStep1ButtonIsDisabled,
  codeHasCorrectLengthAndNoEqual,
  code3DSecureMatchesConstraints,
  codes3DSecureAreNotEquals,
  hasMoreThanThreeNumbers,
};
