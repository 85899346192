// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { DesktopTitleContent } from '../dashboard-total-points-title/dashboard-total-points-desktop-title.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { TitleComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from '../../constants/dashboard-total-points.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Styles
import { DashboardTotalPointsComponentStyled } from '../../dashboard-total-points.component.styled';
// Translations
import translations from '../../translations/dashboard-total-points.translations';
// Types
import { DesktopContentType } from './types/dashboard-total-points-desktop.component.type';

export const DesktopContent = ({
  currency,
  isMobile,
  respectively,
  total,
}: DesktopContentType): React.ReactElement => (
  <DashboardTotalPointsComponentStyled>
    <TitleComponent {...constants.DASHBOARD_POINTS_TITLE_PROPS}>
      <DesktopTitleContent total={total} isMobile={isMobile} />
    </TitleComponent>
    <ParagraphComponent {...constants.DASHBOARD_POINTS_TITLE_PROPS}>
      <FormattedMessageComponent id={translations.RESPECTIVELY} />
      <FormattedNumber
        {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
        value={respectively}
      />
    </ParagraphComponent>
  </DashboardTotalPointsComponentStyled>
);
