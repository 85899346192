// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  CHECK_LINK,
  CVV,
} from '../translations/card-settings-contract-current-card-cvv.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const CARD_SETTINGS_CONTRACT_CURRENT_CARD_CVV_PROPS = {
  label: CVV,
  testId: 'card-settings-contract-current-card-cvv',
};

const CARD_SETTINGS_CONTRACT_CURRENT_CARD_CVV_LINK_PROPS = {
  label: CHECK_LINK,
  testId: 'card-settings-contract-current-card-cvv-link',
  trackingLabel: 'checkCvv',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CVV])
  ),
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_CVV_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_CVV_LINK_PROPS,
};
