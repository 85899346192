// Vendors
import { messageConstants } from '@openbank/cb-ui-commons';
// Translations
import {
  INSURANCE_STATUS,
  TITLE,
} from 'containers/card-settings/views/contract/components/current/components/insurance/components/message/translations/card-settings-contract-current-insurance-message.translations';

const CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_MESSAGE_PROPS = {
  type: messageConstants.TYPES.ERROR,
  testId: 'card-settings-contract-current-insurance-message',
};

const CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DESCRIPTION_PROPS = {
  testId: 'card-settings-contract-current-insurance-description',
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DESCRIPTION_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_MESSAGE_PROPS,
};
