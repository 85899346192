// Translations
import {
  DISABLED,
  ENABLED,
} from 'containers/card-settings/views/contract/components/current/components/control/components/payments/utils/translations/card-settings-contract-current-control-payments.utils.translations';

const getCurrentInternetPaymentStatusTranslation = (isAllowPaymentsNonSecStore: boolean): string =>
  isAllowPaymentsNonSecStore ? ENABLED : DISABLED;

export { getCurrentInternetPaymentStatusTranslation };
