// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { DashboardContextConsumerHook } from 'containers/dashboard/contexts/dashboard.context';
// Events handlers
import { DashboardPointsHandlers } from '../handlers/dashboard-points.handlers';
import { DashboardPointsTrackingHandlers } from '../handlers/dashboard-points.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { DashboardPointsHookType } from './types/dashboard-points.hook.type';

const useDashboardPointsHook = (): DashboardPointsHookType => {
  const [error, setError] = useState<boolean>(false);
  const [fetching, setPageLoader] = useState<boolean>(false);

  const { formatMessage } = useCbIntl();
  const { information } = DashboardContextConsumerHook();

  return {
    ...DashboardPointsHandlers(AppTrackingHook(DashboardPointsTrackingHandlers)),
    ...setPageLoader,
    ...setError,
    error,
    fetching,
    formatMessage,
    ...information.reward,
  };
};

export { useDashboardPointsHook };
