// Vendors
import React from 'react';
// Components
import { TravelPlusComponent } from 'components/travel-plus/travel-plus.component';
// Types
import { CardSettingsContractCurrentTravelModalType } from './types/card-settings-contract-current-travel-modal.type';

const CardSettingsContractCurrentTravelModalComponent = ({
  modalShow,
  onChange: onHide,
}: CardSettingsContractCurrentTravelModalType): React.ReactElement | null =>
  modalShow ? <TravelPlusComponent {...{ onHide }} /> : null;

export { CardSettingsContractCurrentTravelModalComponent };
