// Enumerations
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { LABEL } from '../translations/card-settings-contract-current-control-locations.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_PROPS = {
  label: LABEL,
};

const CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_LINK_PROPS = {
  trackingLabel: 'modifyCardControlLocations',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CARD_CONTROL])
  ),
  urlState: { locations: true },
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_LOCATIONS_LINK_PROPS,
};
