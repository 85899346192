// Constants
import { CARD_SETTINGS_CONTRACT_CURRENT_CONFIGURATION_PROPS } from './constants/card-settings-contract-current.configuration.constants';
// Types
import { CardSettingsResumeLinkConfigurationComponentType } from 'containers/card-settings/components/resume-link/types/card-settings-resume-link-configuration.component.type';
import { CardSettingsContractCurrentConfigurationType } from './types/card-settings-contract-current.configuration.type';
// Utilities
import { cardIsInactive } from 'utils/card.utils';
import { isMainCardSelected } from 'containers/card-settings/views/contract/utils/card-settings-contract.utils';

const getModifyLinkConfiguration = ({
  configuration,
  disabled = false,
  isAddon,
  status,
}: CardSettingsContractCurrentConfigurationType):
  | CardSettingsResumeLinkConfigurationComponentType[]
  | typeof undefined =>
  isMainCardSelected(isAddon)
    ? [
        {
          ...CARD_SETTINGS_CONTRACT_CURRENT_CONFIGURATION_PROPS,
          ...configuration,
          disabled: disabled || cardIsInactive(status),
        },
      ]
    : undefined;

export { getModifyLinkConfiguration };
