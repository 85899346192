// Vendors
import React from 'react';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
import CardSettingsContractCurrentSectionComponent from '../section/card-settings-contract-current-section.component';
// Configurations
import { getModifyLinkConfiguration } from 'containers/card-settings/views/contract/components/current/configurations/card-settings-contract-current.configuration';
// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
import {
  CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_AVAILABLE_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_LIMIT_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_CONSUMED_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_LIMIT_LINK_PROPS,
} from './constants/card-settings-contract-current-credit.constants';
// Hooks
import useCardSettingsContractCurrentCreditHook from './hooks/card-settings-contract-current-credit.hook';
import useCardSettingsContractCurrentLinkHook from '../../hooks/card-settings-contract-current-link.hook';

const CardSettingsContractCurrentCreditComponent = (): React.ReactElement => {
  const {
      availableAmount,
      currencyCode: currency,
      totalLoanBalance,
      formatNumber,
      limit,
    } = useCardSettingsContractCurrentCreditHook(),
    props = { ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency };

  return (
    <CardSettingsContractCurrentSectionComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_DIVIDER_PROPS}
    >
      <CardSettingsResumeLinkComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_LIMIT_PROPS}
        links={getModifyLinkConfiguration({
          ...useCardSettingsContractCurrentLinkHook(),
          configuration: CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_LIMIT_LINK_PROPS,
          disabled: true,
        })}
        value={formatNumber(limit, props)}
      />
      <CardSettingsResumeLinkComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_CONSUMED_PROPS}
        value={formatNumber(totalLoanBalance, props)}
      />
      <CardSettingsResumeLinkComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_AVAILABLE_PROPS}
        value={formatNumber(availableAmount, props)}
      />
    </CardSettingsContractCurrentSectionComponent>
  );
};

export default CardSettingsContractCurrentCreditComponent;
