// Enumerations
import { CardContractInfoSectionsEnumeration } from 'containers/card-settings/views/contract/components/current/enumerations/card-contract-info-sections.enumeration';
// Translations
import { TITLE } from '../translations/card-settings-contract-current-control.translations';

const CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_SECTION_PROPS = {
  id: CardContractInfoSectionsEnumeration.CARD_CONTROL,
  title: TITLE,
};

export { CARD_SETTINGS_CONTRACT_CURRENT_CONTROL_SECTION_PROPS };
