// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  CARD_BENEFITS,
  CARD_BENEFITS_LINK,
} from '../translations/card-settings-contract-current-contract.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const CARD_SETTINGS_CONTRACT_CURRENT_CARD_BENEFITS_PROPS = {
  label: CARD_BENEFITS,
  links: [
    {
      label: CARD_BENEFITS_LINK,
      testId: 'card-settings-contract-current-card-benefits-link',
      trackingLabel: 'viewCardBenefits',
      url: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.BENEFITS_INSURANCE])
      ),
    },
  ],
};

const CARD_SETTINGS_CONTRACT_CURRENT_CARD_ART_PROPS = {
  large: true,
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_ART_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_BENEFITS_PROPS,
};
