// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const DashboardSpentTopGraphicBarsWrapperStyled = styled.div`
  max-width: ${convertPxsToRems({ pixels: 80, base: 14 })};
`;

export const DashboardSpentTopLabelComponentStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const DashboardSpentTopSummariesContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.spacingS};
  margin-top: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${tabletMediaQuery} {
    gap: 0;
    flex-direction: row;
  }

  ${mobileMediaQuery} {
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

export const DashboardSpentTopSummariesContainerTopWrapperStyled = styled.div`
  width: 100%;
`;

export const DashboardSpentTopUpperComponentStyled = styled(DashboardSpentTopLabelComponentStyled)`
  align-items: flex-end;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spaces.spacingS};
`;