// Vendors
import React from 'react';
// Components
import {
  ExpandableRowComponent,
  LineGraphComponent,
  LineSeparatorComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/dashboard-points-line-graph.constants';
// Hooks
import DashboardPointsLinegraphHook from './hooks/dashboard-points-line-graph.component.hook';
// Styles
import { DashboarPointsLineGraphComponentStyled } from './dashboard-points-line-graph.component.style';
// Translations
import translations from './translations/dashboard-points-line-graph.translations';
// Types
import { DashboardLineGraphComponentType } from './types/dashboard-line-graph.component.type';
// Utils
import { getLineGraphProps } from './utils/dashboard-points-line-graph.component.utils';

const DashboardPointsLineGraphComponent = ({
  availablePoints,
  availableCreditPoints,
  collectedPoints,
  collectedCreditPoints,
  currencyCode,
  redeemedPoints,
  redeemedCreditPoints,
}: DashboardLineGraphComponentType): React.ReactElement => {
  const { isMobile } = DashboardPointsLinegraphHook();
  const dataProps = {
    availablePoints,
    availableCreditPoints,
    collectedPoints,
    collectedCreditPoints,
    currencyCode,
    redeemedPoints,
    redeemedCreditPoints,
  };
  const lineGraphProps = getLineGraphProps(dataProps);

  return isMobile ? (
    <DashboarPointsLineGraphComponentStyled>
      <LineSeparatorComponent />
      <ExpandableRowComponent
        {...constants.EXPANDABLE_ROW_PROPS}
        title={
          <FormattedMessageComponent
            id={translations.ACCORDION}
            values={{ value: new Date().getFullYear() }}
          />
        }
      >
        <LineGraphComponent {...lineGraphProps} />
      </ExpandableRowComponent>
    </DashboarPointsLineGraphComponentStyled>
  ) : (
    <LineGraphComponent {...lineGraphProps} />
  );
};

export { DashboardPointsLineGraphComponent };
