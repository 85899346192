// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_STATEMENTS_TABLE_ROW_LINK_PROPS } from './constants/card-settings-statements-table-row-download.constants';
// Hooks
import { useCardSettingsStatementsTableRowDownloadHook } from './hooks/card-settings-statements-table-row-download.hook';
// Styles
import { CardSettingsStatementsTableRowAmountComponentStyled } from '../../card-settings-statements-table-row.component.styled';
// Translations
import { DOWNLOAD_DOCUMENT } from 'containers/card-settings/views/statements/components/row/components/download/translations/card-settings-statements-table-row-download.translations';
// Types
import { CardSettingsStatementsTableRowDownloadComponentType } from './types/card-settings-statements-table-row-download.component.type';

const CardSettingsStatementsTableRowDownloadComponent = (
  props: CardSettingsStatementsTableRowDownloadComponentType
): React.ReactElement | null => {
  const { fetching, handleDownloadCardStatementDocumentLinkClick } =
    useCardSettingsStatementsTableRowDownloadHook(props);

  return (
    <CardSettingsStatementsTableRowAmountComponentStyled>
      <LinkComponent
        {...CARD_STATEMENTS_TABLE_ROW_LINK_PROPS}
        disabled={fetching}
        onClick={handleDownloadCardStatementDocumentLinkClick}
      >
        <FormattedMessageComponent id={DOWNLOAD_DOCUMENT} />
      </LinkComponent>
    </CardSettingsStatementsTableRowAmountComponentStyled>
  );
};

export { CardSettingsStatementsTableRowDownloadComponent };
