// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsContractCurrentControlTransactionsHookType } from '../hooks/types/card-settings-contract-current-control-transactions.hook.type';
// Translations
import { OPERATIVES } from './translations/card-settings-contract-current-control-transactions.translations';

const getAllEnabledCardOperations = ({
  formatMessage,
  ...rest
}: CardSettingsContractCurrentControlTransactionsHookType): any =>
  Object.entries(rest)
    .filter((operative: [string, boolean]) => operative[1])
    .map((operative: [string, boolean]) => operative[0])
    .map((operative: string) => formatMessage({ id: OPERATIVES[operative] }))
    .join(CharactersEnum.COMMA_LINE_BREAK);

export { getAllEnabledCardOperations };
