// Vendors
import { useState } from 'react';
// Contexts
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import { CardSettingsStatementsTableRowDownloadHandlers } from '../handlers/card-settings-statements-table-row-download.handlers';
import { CardSettingsStatementsTableRowDownloadTrackingHandlers } from '../handlers/card-settings-statements-table-row-download.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsStatementsTableRowDownloadHookType } from './types/card-settings-statements-table-row-download.hook.type';
import { CardSettingsStatementsTableRowDownloadComponentType } from 'containers/card-settings/views/statements/components/row/components/download/types/card-settings-statements-table-row-download.component.type';

const useCardSettingsStatementsTableRowDownloadHook = (
  props: CardSettingsStatementsTableRowDownloadComponentType
): CardSettingsStatementsTableRowDownloadHookType => {
  const [fetching, setFetching] = useState<boolean>(false);

  const { setToastConfiguration } = ToastContextConsumerHook();

  return {
    ...CardSettingsStatementsTableRowDownloadHandlers({
      ...AppTrackingHook(CardSettingsStatementsTableRowDownloadTrackingHandlers),
      ...props,
      setToastConfiguration,
      setFetching,
    }),
    fetching,
  };
};

export { useCardSettingsStatementsTableRowDownloadHook };
