// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DashboardPointsComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const DashboardPointsTopComponentStyled = styled.header`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingM};
  height: ${({ theme }) => theme.spaces.spacingXs};
  justify-content: space-between;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.fontSizeP};
  flex: 1;
`;

export const DashboardPointsRowStyled = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingL};
  justify-content: space-between;
  width: 100%;

  ${mobileMediaQuery} {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
