// Translations
import { CARD_NUMBER } from '../translations/card-settings-contract-current-card-number.translations';
import { COPY } from 'translations/link.translations';

const CARD_SETTINGS_CONTRACT_CURRENT_CARD_NUMBER_PROPS = {
  label: CARD_NUMBER,
  testId: 'card-settings-contract-current-card-number',
};

const CARD_SETTINGS_CONTRACT_CURRENT_CARD_NUMBER_LINK_PROPS = {
  label: COPY,
  testId: 'card-settings-contract-current-card-number-link',
  trackingLabel: 'copyCardNumber',
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_NUMBER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CARD_NUMBER_LINK_PROPS,
};
