// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Hooks
import useCardSettingsContractCurrentBillingMethodValueHook from './hooks/card-settings-contract-current-billing-method-value.hook';
// Translations
import { PAYMENT_METHOD_VALUE } from './translations/card-settings-contract-current-billing-method-value.translations';

const CardSettingsContractCurrentBillingMethodValueComponent = (): React.ReactElement | null => {
  const {
    currencyCode: currency,
    fixedPaymentAmount: value,
    paymentMethodCode,
  } = useCardSettingsContractCurrentBillingMethodValueHook();

  return paymentMethodCode ? (
    <FormattedMessageComponent
      id={PAYMENT_METHOD_VALUE[paymentMethodCode]}
      values={{
        amount: (
          <FormattedNumber
            {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency, value }}
          />
        ),
      }}
    />
  ) : null;
};

export default CardSettingsContractCurrentBillingMethodValueComponent;
