// Vendors
import React from 'react';
// Components
import { ToggleComponent } from '@openbank/cb-ui-commons';
import CardSettingsContractCurrentSectionComponent from '../section/card-settings-contract-current-section.component';
import { CardSettingsContractCurrentTravelMessageComponent } from './components/message/card-settings-contract-current-travel-message.component';
import { CardSettingsContractCurrentTravelModalComponent } from './components/modal/card-settings-contract-current-travel-modal.component';
import { CardSettingsContractCurrentTravelDateComponent } from './components/date/card-settings-contract-current-travel-date.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_TOGGLE_PROPS,
} from './constants/card-settings-contract-current-travel.constants';
// Hooks
import { useCardSettingsContractCurrentTravelHook } from './hooks/card-settings-contract-current-travel.hook';
// Styles
import { CardSettingsContractCurrentTravelComponentStyled } from './card-settings-contract-current-travel.component.styled';
import { CardSettingsContractCurrentMessageComponentStyled } from '../../card-settings-contract-current.component.styled';
// Translations
import { SUBSCRIPTION_STATUS } from './translations/card-settings-contract-current-travel.translations';
import { OFF, ON } from 'translations/toggle.translations';

const CardSettingsContractCurrentTravelComponent = (): React.ReactElement => {
  const {
    checked,
    formatMessage,
    handleToggleCardContractTravelPlusModalEvent: onChange,
    modalShow,
  } = useCardSettingsContractCurrentTravelHook();

  return (
    <CardSettingsContractCurrentSectionComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DIVIDER_PROPS}
    >
      <CardSettingsContractCurrentMessageComponentStyled>
        <CardSettingsContractCurrentTravelComponentStyled>
          <ToggleComponent
            {...{
              ...CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_TOGGLE_PROPS,
              onChange,
            }}
            defaultChecked={checked}
            label={<FormattedMessageComponent id={SUBSCRIPTION_STATUS} />}
            labelOff={formatMessage({ id: OFF }).toUpperCase()}
            labelOn={formatMessage({ id: ON }).toUpperCase()}
            value={checked}
          />
          <CardSettingsContractCurrentTravelDateComponent />
        </CardSettingsContractCurrentTravelComponentStyled>
        <CardSettingsContractCurrentTravelMessageComponent />
        <CardSettingsContractCurrentTravelModalComponent {...{ onChange, modalShow }} />
      </CardSettingsContractCurrentMessageComponentStyled>
    </CardSettingsContractCurrentSectionComponent>
  );
};

export default CardSettingsContractCurrentTravelComponent;
