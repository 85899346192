// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { PAYMENT_METHOD } from '../translations/card-settings-contract-current-billing-method.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_PROPS = {
  label: PAYMENT_METHOD,
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_LINK_PROPS = {
  trackingLabel: 'modifyPaymentMethod',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PAYMENT_METHOD])
  ),
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_LINK_PROPS,
};
