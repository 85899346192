// Vendors
import useCbIntl from 'hooks/useCbIntl';
import { useNavigate } from 'react-router-dom';
// Types
import { CardSettingsCheck3dWizardHookType } from 'containers/card-settings/views/check-3d/components/wizard/hooks/types/card-settings-check-3d-wizard.hook.type';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const useCardSettingsCheck3dWizardHook = (): CardSettingsCheck3dWizardHookType => {
  const navigate = useNavigate();
  const { formatMessage } = useCbIntl();
  const { onCancelHybridFlow } = MobileConnectorContextConsumerHook();

  return { formatMessage, navigate, onCancelHybridFlow };
};

export default useCardSettingsCheck3dWizardHook;
