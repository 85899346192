// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import {
  CardSettingsCheck3dConfirmationFooterBuilderHandlersType,
  CardSettingsCheck3dConfirmationFooterBuilderReturnHandlersType,
} from './types/card-settings-check-3d-confirmation-footer.handlers.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const goBackToCardSettingsLandingLinkHandler = ({
  handleGoBackToCardSettingsLandingLinkClickTracking,
  onSuccessHybridFlow,
  navigate,
}: CardSettingsCheck3dConfirmationFooterBuilderHandlersType): void => {
  handleGoBackToCardSettingsLandingLinkClickTracking();
  onSuccessHybridFlow({ navigate, url: generateAbsoluteRoute(RoutesEnumeration.CARD_SETTINGS) });
};

const CardSettingsCheck3dConfirmationFooterHandlers = (
  props: CardSettingsCheck3dConfirmationFooterBuilderHandlersType
): CardSettingsCheck3dConfirmationFooterBuilderReturnHandlersType => ({
  handleGoBackToCardSettingsLandingLinkClick: () => goBackToCardSettingsLandingLinkHandler(props),
});

export default CardSettingsCheck3dConfirmationFooterHandlers;
