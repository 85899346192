// Types
import {
  CardSettingsContractCurrentTravelBuilderMethodsHandlersType,
  CardSettingsContractCurrentTravelHandlersType,
} from './types/card-settings-contract-current-travel.handlers.type';

const updateTravelPlusModalVisibility =
  (trackingFunction: (visible: boolean) => void): ((visible: boolean) => boolean) =>
  (preVisible: boolean) => {
    trackingFunction(!preVisible);
    return !preVisible;
  };

const toggleCardContractTravelPlusModalEventHandler = ({
  checked,
  handleToggleCardContractTravelPlusModalEventTracking,
  setModalShow,
  setChecked,
}: CardSettingsContractCurrentTravelHandlersType): void => {
  setModalShow(
    updateTravelPlusModalVisibility(handleToggleCardContractTravelPlusModalEventTracking)
  );
  if (checked !== undefined) {
    setChecked(checked);
  }
};

const CardSettingsContractCurrentTravelHandlers = (
  props: CardSettingsContractCurrentTravelHandlersType
): CardSettingsContractCurrentTravelBuilderMethodsHandlersType => ({
  handleToggleCardContractTravelPlusModalEvent: (checked?: boolean) =>
    toggleCardContractTravelPlusModalEventHandler({ ...props, checked }),
});

export { CardSettingsContractCurrentTravelHandlers };
