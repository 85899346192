// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import ToggleButtonComponent from './components/toggle-button/toggle-button.component';
import EnableDisableCardConfirmationModal from 'components/enable-disable-card-confirmation-modal/enable-disable-card-confirmation-modal.component';
// Constants
import constants from './constants/card-settings-toggles.constants';
// Hooks
import CardSettingsTogglesHook from './hooks/card-settings-toggles.hook';
// Translations
import translations from './translations/card-settings-toggles.translations';
// Styles
import {
  CardSettingsTogglesComponentStyled,
  CardSettingsTogglesLeftComponentStyled,
} from './card-settings-toggles.component.styled';
// Utilities
import { cardIsActive, isLinkDisabled } from '../../utils/card-settings.utils';
import { checkExpirationDate } from './components/toggle-button/utils/toggle-button.utils';
import { CardSettingsTogglesTravelPlusModalComponent } from 'containers/card-settings/views/landing/components/toggles/components/travel-plus-modal/card-settings-toggles-travel-plus-modal.component';

const CardSettingsTogglesComponent = (): React.ReactElement => {
  const {
    cardStatusLoading,
    handleConfirmActivityCardToggleChange,
    handleDismissActivityCardToggleChange,
    handleToggleActivityCardToggleChange,
    handleToggleTravelPlusToggleChange: onClick,
    formatMessage,
    showConfirmationModal,
    status,
    travelPlus,
    showTravelPlusModal,
    actions,
  } = CardSettingsTogglesHook();

  const expiration = travelPlus?.expirationDate
    ? checkExpirationDate(travelPlus.expirationDate)
    : false;

  return (
    <CardSettingsTogglesComponentStyled>
      {/* Card Status */}
      <CardSettingsTogglesLeftComponentStyled>
        <ToggleButtonComponent
          disabled={isLinkDisabled(actions, constants.CARD_SETTINGS_TOGGLE_STATUS_PROPS.id)}
          label={
            <FormattedMessageComponent id={translations.SETTINGS_CARD_CONTROL_LOCATIONS_WARNING} />
          }
          labelOff={formatMessage({ id: translations.OFF }).toUpperCase()}
          labelOn={formatMessage({ id: translations.ON }).toUpperCase()}
          onClick={handleToggleActivityCardToggleChange}
          value={cardIsActive(status)}
          testId={constants.CARD_SETTINGS_TOGGLE_STATUS_PROPS.testId}
        />
      </CardSettingsTogglesLeftComponentStyled>
      {showConfirmationModal && (
        <EnableDisableCardConfirmationModal
          isEnabled={cardIsActive(status)}
          loadingStatus={cardStatusLoading}
          onConfirm={handleConfirmActivityCardToggleChange}
          onDismiss={handleDismissActivityCardToggleChange}
        />
      )}

      {/* Travel Plus */}
      <ToggleButtonComponent
        {...{ onClick }}
        disabled={isLinkDisabled(actions, constants.CARD_SETTINGS_TOGGLE_TRAVEL_PROPS.id)}
        loadingStatus={cardStatusLoading}
        label={<FormattedMessageComponent id={translations.TRAVEL_PLUS} />}
        labelOff={formatMessage({ id: translations.OFF }).toUpperCase()}
        labelOn={formatMessage({ id: translations.ON }).toUpperCase()}
        value={travelPlus?.activated}
        expirationDate={travelPlus?.expirationDate}
        testId={constants.CARD_SETTINGS_TOGGLE_TRAVEL_PROPS.testId}
      />
      <CardSettingsTogglesTravelPlusModalComponent {...{ onClick, showTravelPlusModal }} />
    </CardSettingsTogglesComponentStyled>
  );
};

export { CardSettingsTogglesComponent };
