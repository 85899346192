// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsContractCurrentTravelDateHookType } from './types/card-settings-contract-current-travel-date.hook.type';

const useCardSettingsContractCurrentTravelDateHook =
  (): CardSettingsContractCurrentTravelDateHookType => {
    const { formatDate } = useCbIntl();

    const { currentCard } = AppContextConsumerHook(),
      { activated, renovationDate } = currentCard?.travelPlus || {};

    return {
      activated,
      formatDate,
      renovationDate,
    };
  };

export { useCardSettingsContractCurrentTravelDateHook };
