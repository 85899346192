// Vendors
import React from 'react';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DATE_PROPS } from './constants/card-settings-contract-current-travel-date.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Hooks
import { useCardSettingsContractCurrentTravelDateHook } from './hooks/card-settings-contract-current-travel.hook';
// Utilities
import {
  getRenovationDateTooltipLabel,
  getTravelPlusRenovationDate,
} from './utils/card-settings-contract-current-travel-date.utils';

const CardSettingsContractCurrentTravelDateComponent = (): React.ReactElement => {
  const { activated, formatDate, renovationDate } = useCardSettingsContractCurrentTravelDateHook();

  return (
    <CardSettingsResumeLinkComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_DATE_PROPS}
      tooltipMessage={
        <FormattedMessageComponent
          id={getRenovationDateTooltipLabel(activated)}
          values={{
            date: formatDate(renovationDate, MiscellaneousDateConstants.FORMAT_DD_MM_YYYY),
          }}
        />
      }
      value={getTravelPlusRenovationDate({ activated, formatDate, renovationDate })}
    />
  );
};

export { CardSettingsContractCurrentTravelDateComponent };
