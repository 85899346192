// Vendors
import React from 'react';
// Components
import CardSettingsContractSidebarComponent from './components/sidebar/card-settings-contract-sidebar.component';
import CardSettingsContractCurrentComponent from './components/current/card-settings-contract-current.component';
import CardSettingsContractOthersComponent from './components/others/card-settings-contract-others.component';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { CardSettingsContractViewStyled } from './card-settings-contract.view.styled';

const CardSettingsContractView = (): React.ReactElement => (
  <LayoutGridContentComponentStyled>
    <LayoutGridContentLeftComponentStyled>
      <CardSettingsContractViewStyled>
        <CardSettingsContractCurrentComponent />
        <CardSettingsContractOthersComponent />
      </CardSettingsContractViewStyled>
    </LayoutGridContentLeftComponentStyled>
    <LayoutGridContentRightComponentStyled>
      <CardSettingsContractSidebarComponent />
    </LayoutGridContentRightComponentStyled>
  </LayoutGridContentComponentStyled>
);

export default CardSettingsContractView;
